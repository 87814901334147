<template>
	<div class="menu-wrapper">
		<template v-if="item.menuType === 'C'">
			<app-link :to="resolvePath(item.path)">
				<el-menu-item :index="resolvePath(item.path)" :class="{ 'submenu-title-noDropdown': !isNest }" :title="generateTitle(item.menuName)">
					<item :icon="item.meta && item.meta.icon" :title="generateTitle(item.menuName)" />
				</el-menu-item>
			</app-link>
		</template>

		<el-submenu v-else ref="subMenu" :index="resolvePath(item.path || item.menuName)" popper-append-to-body>
			<template slot="title">
				<item :icon="item.meta && item.meta.icon" :title="generateTitle(item.menuName)" />
			</template>
			<template v-for="child in item.children">
				<sidebar-item
					:key="child.menuName"
					:is-nest="true"
					:item="child"
					v-if="show(child)"
					:base-path="resolvePath(child.path)"
					:class="{ 'nest-menu': true, 'side-nav-item__active': currentNavPath === child.path }"
				/>
			</template>
		</el-submenu>
	</div>
</template>
<script>
import path from 'path';
import Item from './Item';
import { isExternal } from '@/utils/validate';
import AppLink from './Link';
import { generateTitle } from '@/utils/i18n';

export default {
	name: 'SidebarItem',
	components: {
		Item,
		AppLink
	},
	props: {
		// route object
		item: {
			type: Object,
			required: true
		},
		isNest: {
			type: Boolean,
			default: false
		},
		basePath: {
			type: String,
			default: ''
		}
	},
	data() {
		this.onlyOneChild = null; // 定义在return外，避免递归渲染时，和语言切冲突，导致死循环报错
		return {
			comId: '',
			currentNavPath: ''
		};
	},
	computed: {
		menusPermission() {
			const { menuItemTopId, menusPermission = [] } = this.$store.state.app;

			if (menuItemTopId) {
				return menusPermission?.filter(({ topId }) => topId === menuItemTopId);
			}
			return menusPermission;
		}
	},
	watch: {
		$route: {
			handler(val) {
				if (val.redirectedFrom) {
					this.currentNavPath = val.redirectedFrom;
				} else {
					this.currentNavPath = val.path;
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.comId = localStorage.getItem('comId');
	},
	methods: {
		generateTitle,
		show(child) {
			return child.name !== 'customer' || (child.name === 'customer' && this.comId === '1');
			// return true
		},
		resolvePath(routePath) {
			if (isExternal(routePath)) {
				return routePath;
			}
			if (isExternal(this.basePath)) {
				return this.basePath;
			}
			return path.resolve(this.basePath, routePath);
		}
	}
};
</script>
<style lang="scss">
.el-menu-item.is-active {
	background-color: #3070cf !important;
}
.side-nav-item__active {
	.el-menu-item {
		background-color: #3070cf !important;
		&::after {
			content: '';
			width: 5px;
			height: 15px;
			position: absolute;
			right: 0;
			border-right: 7.5px solid #fff;
			border-top: 7.5px solid transparent;
			border-bottom: 7.5px solid transparent;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-submenu__title,
::v-deep.el-menu-item.submenu-title-noDropdown {
	font-weight: bold;
}
.nest-menu {
	font-weight: normal;
	::v-deep.el-submenu__title {
		font-weight: normal;
	}
}
</style>
