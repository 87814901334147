export default {
    route: {
        'model-library': 'Car Library',
        'news-library': 'News Library',
        'tag-library': 'Tag Library',
        'recommend': 'Recommendy',
        'system-config': 'System Config',
        'brand': 'Brand Manage',
        'model': 'Model Manage',
        'car': 'Varient Manage',
        'configmanage': "Config Manage",
        'article-manage': 'Article Manage',
        'column-manage': 'Channel Manage',
        'configure-manage': 'News Config',
        'car-tag': 'Variant Tag',
        'bottomprice': 'Bottom Price',
        'model-tag': 'Model Tag',
        'news-tag': 'News Tag',
        'hot-list': 'Hot List',
        'banner-list': 'Banner List',
        'country-config': 'Country Config',
        'params-config': 'Params Config',
        'rightsprofile': "Rights Profile",
        'admin': "Admin",
        "role": "Role"
    },
    components: {
        documentation: 'Documentation',
        tinymceTips: 'Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.',
        dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
        stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
        backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
        backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
        imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
    },
}