import Vue from 'vue';
import loadmore from './loadmore';
import upload from './upload';
const directive = [
	{
		name: 'loadmore',
		content: loadmore
	},
	{
		name: 'upload',
		content: upload
	}
];

directive.map((directive) => {
	Vue.directive(directive.name, directive.content);
});
