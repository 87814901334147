import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
import getters from './getters'
import settings from './modules/settings'
import setData from './modules/setData'
import app from './modules/app'
import approval from './modules/approval';
import wap from './modules/wap'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    approval,
    wap,
    settings,
    setData
  },
  plugins: [
    persistedState({ storage: window.sessionStorage })
  ],
  getters
})

export default store