import Cookies from 'js-cookie';
import { getLanguage } from '@/lang';
import { getDict, PlanRange, permissionData, getPermissionData } from '@/api/common.js';
import { isDev, transfromToTree, Work } from '@/utils/util';
import router from '@/router/router';
const state = {
	range: {},
	sidebar: {
		opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
		withoutAnimation: false
	},
	companyPrin: false,
	orgIds: null,
	orgId: null,
	device: 'desktop',
	language: getLanguage(),
	dict: null,
	staffId: null,
	userType: null,
	permission: null,
	menus: [],
	staffMenus: []
};

const mutations = {
	SET_LANGUAGE: (state, language) => {
		state.language = language;
		Cookies.set('language', language);
	},

	SET_DICT: (state, dict) => {
		state.dict = dict;
	},
	SET_STAFFID: (state, staffId) => {
		state.staffId = staffId;
	},
	SET_COMPANYPRIN: (state, companyPrin) => {
		state.companyPrin = companyPrin;
	},
	SET_USERTYPE: (state, userType) => {
		state.userType = userType;
	},
	SET_ORGIDS: (state, orgIds) => {
		state.orgIds = orgIds;
	},
	SET_ORGID: (state, orgId) => {
		state.orgId = orgId;
	},
	SET_RANGE: (state, range) => {
		state.range = range;
	},
	TOGGLE_SIDEBAR: (state) => {
		state.sidebar.opened = !state.sidebar.opened;
		state.sidebar.withoutAnimation = false;
		if (state.sidebar.opened) {
			Cookies.set('sidebarStatus', 1);
		} else {
			Cookies.set('sidebarStatus', 0);
		}
	},
	CLOSE_SIDEBAR: (state, withoutAnimation) => {
		Cookies.set('sidebarStatus', 0);
		state.sidebar.opened = false;
		state.sidebar.withoutAnimation = withoutAnimation;
	},
	TOGGLE_DEVICE: (state, device) => {
		state.device = device;
	},
	SET_MENU: (state, menuItemTopId) => {
		state.menuItemTopId = menuItemTopId;
	}
};
const actions = {
	toggleSideBar({ commit }) {
		commit('TOGGLE_SIDEBAR');
	},
	closeSideBar({ commit }, { withoutAnimation }) {
		commit('CLOSE_SIDEBAR', withoutAnimation);
	},
	toggleDevice({ commit }, device) {
		commit('TOGGLE_DEVICE', device);
	},
	setLanguage({ commit }, language) {
		commit('SET_LANGUAGE', language);
	},
	setrange({ commit }) {
		return new Promise((resolve) => {
			PlanRange().then((res) => {
				if (res) {
					commit('SET_RANGE', res);
					resolve(res);
				} else {
					commit('SET_RANGE', {});
					console.warn('加载失败');
				}
			});
		});
	},
	getDict({ commit }) {
		return new Promise((resolve) => {
			getDict().then((res) => {
				if (res) {
					commit('SET_DICT', res);
					resolve(res);
				} else {
					commit('SET_DICT', []);
					console.warn('字典表加载失败');
				}
			});
		});
	},
	async setPermission({ state }) {
		if ((Cookies.get('token') && Cookies.get('token') != null && Cookies.get('token') != '') || isDev) {
			let res = await permissionData({
				request: {}
			});
			if (!res.code) {
				state.permission = res;
			}
		}
	},
	// delPermission({ state }) {
	// 	state.permission = false;
	// },
	async setMenusPermission({ state }) {
		if ((Cookies.get('token') && Cookies.get('token') != null && Cookies.get('token') != '') || isDev) {
			let res = await getPermissionData({
				request: {}
			});
			if (!res.code) {
				if (window.Worker) {
					const r  = await Work.run({ type: 'menu', data: { res, routers: router.options.routes, state }});
					const { allMenusList, menuList } = r;
					state.menusPermission = allMenusList;
					state.menus = menuList.filter(({ moduleType }) => moduleType === 1); // 管理员
					state.staffMenus = menuList.filter(({ moduleType }) => moduleType === 2); // 员工
					// const result = await new Promise((resolve) => {
					// 	const worker = new Worker('/static/js/work.js');
					// 	worker.onmessage = function (e) {
					// 		const data = JSON.parse(e.data);
					// 		const { allMenusList, menuList } = data;
					// 		state.menusPermission = allMenusList;
					// 		state.menus = menuList.filter(({ moduleType }) => moduleType === 1); // 管理员
					// 		state.staffMenus = menuList.filter(({ moduleType }) => moduleType === 2); // 员工
					// 		resolve(data);
					// 		worker.terminate();
					// 	};
					// 	const routers = router.options.routes;
					// 	worker.postMessage(JSON.stringify({ res, routers }));
					// });
					return r;
				}
				console.log('no Worker');
				setTimeout(() => {
					const allMenusTree = transfromToTree(res);
					state.menusPermission = res;

					const routers = router.options.routes;
					// M 目录 C 菜单 F 按钮
					const allMenus = res.filter(({ isShow, menuType }) => isShow === 1 && ['M', 'C'].includes(menuType));
					allMenus.forEach((e) => {
						if (e.component) {
							const routeData = routers.find(({ name }) => e.component === name);
							if (routeData) {
								e.meta = routeData.meta;
							}
						}
					});
					const menuList = transfromToTree(allMenus);

					state.menus = menuList.filter(({ moduleType }) => moduleType === 1); // 管理员
					state.staffMenus = menuList.filter(({ moduleType }) => moduleType === 2); // 员工
				}, 0);
			}
		}
	},
	setMenuItemTopId({ commit }, params) {
		commit('SET_MENU', params);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
