/*
 * @Author: 卢均锐morye
 * @Date: 2020-04-28 09:37:30
 * @LastEditTime: 2020-05-07 13:37:10
 */
/** When your routing table is too long, you can split it into small modules**/

// import Layout from '../../views/achievementsmanagement/layout/index.vue';
import Layout from '@/layout';
import { Noop } from '@/utils/eleNoop';

const Achievements = {
	path: '/achievements',
	component: Noop,
	name: 'AchievementsList',
	redirect: '/achievements/index',
	meta: {
		title: 'achievementsmanagement'
		// icon: '绩效管理@1x'
	},
	children: [
		{
			path: 'index',
			name: 'AchievementsList',
			component: () => import('@/views/achievementsmanagement/index'),
			meta: {
				title: 'achievementsmanagement'
				// icon: "绩效管理@1x",
			}
		}
		// {
		//     path: 'create',
		//     name: 'AchievementsCreate',
		//     hidden : true,
		//     component: () => import ('@/views/achievementsmanagement/create'),
		//     meta: {
		//         title: 'achievementsmanagement',
		//         icon: '绩效管理@1x'
		//     },
		// }
	]
};
export default Achievements;
