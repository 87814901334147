
<template>
  <div class="app-list-content"
       ref="appListContent"
       :class="['app-layout-'+ size, 'app-layout-'+direction]"
       :style="{height: height}">
    <div class="app-list-header" v-if="needHeader" :style="sbdx?'background-color:#f6f7fb':''">
      <el-scrollbar v-if="direction == 'row'"
                    style="height: 100%">
        <slot name="header"></slot>
      </el-scrollbar>
      <slot name="header"
            v-else></slot>
    </div>
    <div class="app-list-center"
         ref="center"
         @scroll="showToTop($event)">
      <div class="center-position"
           v-if="height == '100%'"
           ref="centerPosition"
           @scroll="showToTop($event)">
        <slot></slot>
      </div>
      <slot v-else></slot>
    </div>
    <div class="app-list-footer"
         v-if="needFooter">
      <slot name="footer"></slot>
    </div>
    <div class="to-top"
         v-show="scrollTopShow"
         @click="toTop"
         v-if="backTop">
      <i class="fa fa-arrow-up icon"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppLayout',
  data () {
    return {
      titleCode: this.value,
      scrollTopShow: false,
      computedStyleHeight: '100%'
    };
  },
  mounted () {
    this.computedStyleHeight = `calc(100% - ${this.$refs.appListContent.offsetTop}px)`;
  },
  computed: {
    scrollDom () {
      if (this.height == '100%') {
        return this.$refs.centerPosition;
      }
        return this.$refs.center;

    }
  },
  props: {
    value: [String, Number] /* title为数组时绑定的值 */,
    direction: {
      type: String,
      default: 'column' /* column / row */
    },
    size: {
      type: String,
      default: 'medium' /* medium / small / mini / none */
    },
    height: {
      type: String,
      default: '100%' /* 100% / auto */
    },
    needHeader: {
      type: Boolean,
      default: true
    },
    needFooter: {
      type: Boolean,
      default: true
    },
    backTop: {
      type: Boolean,
      default: false
    },
    sbdx: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    titleClick (code) {
      if (code == this.titleCode) {return;}
      this.titleCode = code;
      this.$emit('input', code);
      this.$emit('title-click', code);
    },
    toTop () {
      const element = this.scrollDom;

      const from = element.scrollTop;
        const to = 0;
        const duration = 500;

      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame =
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function (callback) {
            return window.setTimeout(callback, 1000 / 60);
          };
      }
      const s = Math.abs(from - to);
      const v = Math.ceil((s / duration) * 50);

      function scroll (start, end, step) {
        if (start === end) {return;}
        let d = start + step > end ? end : start + step;

        if (start > end) {
          d = start - step < end ? end : start - step;
        }
        element.scrollTo(d, d);
        window.requestAnimationFrame(() => scroll(d, end, step));
      }
      scroll(s, to, v);
    },
    showToTop (e) {
      if (this.backTop) {
        this.scrollTopShow = e.target.scrollTop > e.target.clientHeight;
      }
    }
  },
  watch: {
    value (val) {
      if (val !== this.titleCode) {
        this.titleCode = val;
      }
    }
  }
};
</script>

<style lang="scss">
@import "./scrollbar.scss";
$color-primary: #409eff;
@mixin title-position {
  position: absolute;
  left: 30px;
  top: 0px;
}
@mixin title-style {
  background: $color-primary;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  box-shadow: 1px 3px 5px 0px $color-primary;
  padding: 6px 12px;
}
.app-list-content {
  position: relative;
  background: #ffffff;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: width 0.4s;
  .app-list-header {
    position: relative;
    padding: 10px 0;
    // box-shadow: 0px 1px #eaeaea;
    z-index: 1;
    .list-title {
      z-index: 2;
      @include title-position;
      @include title-style;
    }
    .list-titles {
      z-index: 2;
      @include title-position;
      display: flex;
      .title-item {
        @include title-style;
        transform: scale(0.8);
        opacity: 0.8;
        cursor: pointer;
        transition: transform 0.3s, opacity 0.3s;
      }
      .title-active {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .app-list-center {
    flex-grow: 1;
    flex: 1;
    overflow: auto;
    position: relative;
    .center-position {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
  .app-list-footer {
    border-top: 1px solid #eaeaea;
    padding: 5px;
  }
}
.app-layout-medium {
  .app-list-header {
    padding: 14px 12px;
  }
}
.app-layout-small {
  .app-list-header {
    padding: 8px 12px;
  }
}
.app-layout-mini {
  .app-list-header {
    padding: 4px 12px;
  }
}
.app-layout-none {
  .app-list-header {
    padding: 4px 0;
  }
}
.app-layout-row {
  flex-direction: row;
  .app-list-header {
    border-bottom: none;
    height: 100%;
    text-align: left;
  }
}
.app-layout-view {
  width: 50%;
}
.to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  position: absolute;
  right: 52px;
  bottom: 60px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: rgba(231, 234, 241, 0.9);
  color: #9aaabf;
  transition: color 0.4s;
  &:hover {
    color: rgb(100, 180, 255);
  }
  .icon {
    font-size: 20px;
  }
}
</style>
