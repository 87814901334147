import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store';
import './registerServiceWorker';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import axios from 'axios';
import './components/Layout';
import './directive';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css'; // A modern alternative to CSS resets
import '@/styles/index.scss'; // global css
import '@/commonApi.js';
import Print from 'vue-print-nb';
// import './permission' // permission control
import '@/icons'; // icon
import '@/font/iconfont.css';
import i18n from './lang'; // internationalization
import Vue2OrgTree from 'vue2-org-tree'; // 组织结构图插件
import './filters';
import elCascaderMulti from 'el-cascader-multi';
// import globalVal from './components/global/global'
import VueDND from 'awe-dnd';
import * as echarts from 'echarts';
import XEUtils from 'xe-utils';
import zhCN from 'vxe-table/lib/locale/lang/zh-CN';
import { VXETable } from 'vxe-table';
// import ckeditor from 'ckeditor4-vue';
VXETable.setup({
	i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
});
Vue.prototype.$echarts = echarts;
Vue.use(VueDND);
Vue.use(elCascaderMulti);
Vue.use(Vue2OrgTree);
// Vue.use(ckeditor);
Vue.prototype.$axios = axios;
Vue.use(ElementUI, {
	locale,
	i18n: (key, value) => i18n.t(key, value)
});
Vue.use(Print);
Vue.config.productionTip = false;
/**
 * 控制 菜单 tab权限
 */
Vue.prototype.permissionControl = function (keyword) {
	const needControlKey = ['StaffApproval', 'StaffInfo'];
	let perssion =
		store.state.app.menusPermission?.filter(
			({ menuType, isShow, component }) => (menuType === 'F' || needControlKey.includes(component)) && isShow === 1
		) || [];

	if (Array.isArray(keyword)) {
		if (perssion.find(({ component }) => keyword.includes(component))) {
			return true;
		}
	}
	if (perssion.find(({ component }) => component === keyword)) {
		return true;
	}
	return false;
};
/**
 * 控制按钮权限
 */
// const btns = [
// 	{ text: '导入', key: 'Import' },
// 	{ text: '下载模板', key: 'Download' },
// 	{ text: '导出', key: 'Export' },
// 	{ text: '编辑', key: 'Edit' },
// 	{ text: '新增', key: 'Add' },
// 	{ text: '组织架构图', key: 'OrgMap' },
// 	{ text: '审批设置', key: 'ApprovalSettings' },
// 	{ text: '岗位说明书', key: 'JobBookView' },
// 	{ text: '设置', key: 'Set' },
// 	{ text: '录入', key: 'Enter' },
// 	{ text: '草稿箱', key: 'Draft' },
// 	{ text: '打印', key: 'Print' },
// 	{ text: '员工删除', key: 'DeleteEmployee' },
// 	{ text: '员工删除记录', key: 'DeleteRecord' },
// 	{ text: '草稿删除', key: 'DraftDelete' },
// 	{ text: '筛选项', key: 'Filter' },
// 	{ text: '排序', key: 'Sort' },
// 	{ text: '进入钉钉平台', key: 'ToDing' },
// 	{ text: '导入通讯录', key: 'ImportAddressBook' },
// 	{ text: '导入原始数据', key: 'ImportOriginalData' },
// 	{ text: '编辑图标', key: 'EditCell' },
// 	{ text: '新增图标', key: 'AddCell' },
// 	{ text: '删除图标', key: 'DelCell' },
// 	{ text: '雷达分析图', key: 'RadarChart' },
// 	{ text: '编辑愿景', key: 'EditVision' },
// 	{ text: '编辑年度里程碑', key: 'EditTarget' },
// 	{ text: '删除年度里程碑', key: 'DelMilepost' },
// 	{ text: '新增年度里程碑', key: 'AddMilepost' },
// 	{ text: '公布考核结果', key: 'AppraisalResults' },
// 	{ text: '批量删除', key: 'BatchDel' },
// 	{ text: '人才评估', key: 'TalentAssessment' },
// 	{ text: '申报核心人力', key: 'Declare' },
// 	{ text: '详情', key: 'Detail' },
// 	{ text: '调整', key: 'Change' },
// 	{ text: '带宽匹配', key: 'Matching' },
// 	{ text: '成本配置', key: 'Config' },
// 	{ text: '发放工资条', key: 'Grant' },
// 	{ text: '发放所有工资条', key: 'GrantAll' },
// 	{ text: '清空工资记录', key: 'Clear' },
// 	{ text: '年度行权系数调整', key: 'ChangeRatio' },
// 	{ text: '申请', key: 'Apply' },
// ];
Vue.prototype.permissionControlBtns = function (pageName, btnKey) {
	if (!pageName) return false;
	let perssion = store.state.app.menusPermission?.filter(({ isOperation, menuType }) => isOperation === 1 && ['C', 'F'].includes(menuType)) || [];
	if (perssion.find(({ component }) => component === pageName)) {
		return true;
	}
	return false;
};

Vue.prototype.formatTimes = function (value) {
	let date = new Date(value);
	let y = date.getFullYear();
	let MM = date.getMonth() + 1;
	MM = MM < 10 ? '0' + MM : MM;
	let d = date.getDate();
	d = d < 10 ? '0' + d : d;
	let h = date.getHours();
	h = h < 10 ? '0' + h : h;
	let m = date.getMinutes();
	m = m < 10 ? '0' + m : m;
	let s = date.getSeconds();
	s = s < 10 ? '0' + s : s;
	return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
};
Vue.prototype.setActiveTab = function (pageName, activeList) {
	const data = store.state.app.menusPermission.find(({ component }) => pageName === component);
	const currenChildren = data?.children?.filter(({ isShow }) => isShow) || [];
	if (currenChildren.length) {
		let realActiveName = this.activeName;
		switch (pageName) {
			case 'CoreReview':
				realActiveName = this.navActive;
				break;
			case 'StaffApproval':
				realActiveName = this.activeName2;
				break;
			case 'RecruitmentDeployment':
				realActiveName = this.table2Params.queryType;
				break;

			default:
				break;
		}
		if (typeof realActiveName !== 'string') {
			realActiveName = realActiveName.toString();
		}
		const isHasActive = !!activeList.find(({name})=> name === realActiveName);
		const setAct = (firstKey) => {
			if (pageName === 'CoreReview') {
				// 核心人力复查那一坨
				this.navActive = activeList.find(({ key }) => key === firstKey)?.name || '';
			} else if (pageName === 'StaffApproval') {
				// 审批那一坨
				this.activeName2 = activeList.find(({ key }) => key === firstKey)?.name || '';
			} else if (pageName === 'RecruitmentDeployment') {
				this.table2Params.queryType = activeList.find(({ key }) => key === firstKey)?.name || '';
			} else {
				this.activeName = activeList.find(({ key }) => key === firstKey)?.name || '';
			}

		};
		if (isHasActive) {
			const actData = activeList.find(({ name }) => this.activeName === name);
			const currentAct = currenChildren.find(({ component }) => component === actData?.key);
			if (!currentAct) {
				const firstKey = currenChildren[0]?.component;
				setAct(firstKey);
			}
		} else {
			const firstKey = activeList[0]?.key;
			setAct(firstKey);
		}
	}
};
new Vue({
	el: '#app',
	router,
	i18n,
	store,
	render: (h) => h(App)
});
