import Layout from '@/layout';
import { Noop } from '@/utils/eleNoop';
import attendanceManage from './attendanceManage'; // 考勤管理
// import recruitmentManagement from './recruitmentManagement'; // 招聘管理
// import viewResumes from './viewResumes'; // 简历库

const organizingpersonnel = {
	path: '/organizingpersonnel',
	component: Layout,
	name: 'OrganizationPersonnel',
	meta: {
		title: '基础人事',
		icon: 'organizingpersonnel'
	},
	children: [
		{
			path: '/organizing',
			name: 'Organizing',
			redirect: '/organizing/organizationalManagement',
			component: () => import('@/views/organizingpersonnel/organizing/index'),
			// import ('@/views/developping/index'),
			meta: {
				title: '组织与职位'
			},
			children: [
				{
					path: 'organizationalManagement',
					name: 'OrganizationalManagement',
					// hidden: true,
					component: () => import('@/views/organizingpersonnel/organizing/organizational/index'),
					meta: {
						title: '组织管理'
					}
				},
				{
					path: 'positionManagement',
					name: 'PositionManagement',
					// hidden: true,
					component: () => import('@/views/organizingpersonnel/position/index'),
					meta: {
						title: '职位管理'
					}
				}
			]
		},
		// {
		// 	path: '/position',
		// 	name: 'Position',
		// 	redirect: '/position/list',
		// 	component: () => import('@/views/organizingpersonnel/position/index'),
		// 	// import ('@/views/developping/index'),
		// 	meta: {
		// 		title: 'position'
		// 	},
		// 	children: [
		// 		{
		// 			path: 'list',
		// 			hidden: true,
		// 			component: () => import('@/views/organizingpersonnel/position/list'),
		// 			// import ('@/views/developping/index'),
		// 			meta: {
		// 				title: '列表'
		// 			}
		// 		}
		// 	]
		// },
		{
			path: '/humanaffairs',
			name: 'Humanaffairs',
			redirect: '/humanaffairs/personnelInformation',
			// url: '/v1/car/list',
			component: Noop,
			// import ('@/views/developping/index'),
			meta: {
				title: '人事管理'
			},
			children: [
				// {
				// 	path: 'list',
				// 	hidden: true,
				// 	component: () => import('@/views/organizingpersonnel/humanaffairs/list'),
				// 	// import ('@/views/developping/index'),
				// 	meta: {
				// 		title: '列表'
				// 	}
				// },
				{
					path: 'personnelInformation',
					name: 'PersonnelInformation',
					component: () => import('@/views/organizingpersonnel/humanaffairs/information'),
					meta: {
						title: '人事信息'
					}
				},
				{
					path: 'employeeRelations',
					name: 'EmployeeRelations',
					// component: () => import('@/views/organizingpersonnel/humanaffairs/list'),
					component: () => import('@/views/organizingpersonnel/humanaffairs/employeeRelations/list.vue'),
					meta: {
						title: '员工关系'
					}
				},
				{
					path: 'attendanceManage',
					name: 'AttendanceManage',
					// component: () => import('@/views/organizingpersonnel/humanaffairs/list'),
					component: () => import('@/views/organizingpersonnel/humanaffairs/information/attendanceList.vue'),
					meta: {
						title: '考勤管理'
					}
				},
				{
					path: 'apply',
					hidden: true,
					component: () => import('@/views/organizingpersonnel/humanaffairs/apply'),
					meta: {
						title: '申请'
					}
				},
				{
					path: 'draftBox',
					name: 'DraftBox',
					hidden: true,
					component: () => import('@/views/organizingpersonnel/humanaffairs/information/personnel/draftBox.vue'),
					meta: {
						title: '草稿箱'
					}
				},
				{
					path: 'quit',
					name: 'ConfigQuit',
					url: '/v1/org/quit/config/list',
					hidden: true,
					component: () => import('@/views/organizingpersonnel/humanaffairs/employeeRelations/reasonLeave.vue'),
					meta: {
						title: '离职类型配置'
					}
				}
			]
		},
		// attendanceManage,
		// recruitmentManagement,
		// viewResumes,
		{
			path: '/customer',
			name: 'customer',
			hidden: false,
			redirect: '/customer/list',
			component: () => import('@/views/organizingpersonnel/customer/index'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'customer'
			},
			children: [
				{
					path: 'list',
					hidden: false,
					component: () => import('@/views/organizingpersonnel/customer/list'),
					// import ('@/views/developping/index'),
					meta: {
						title: '客户'
					}
				}
			]
		}
	]
};
export default organizingpersonnel;
