import Layout from '@/layout';
import { Noop } from '@/utils/eleNoop';
import coreHuman from './corehuman'; // 核心人力

const useTalentCorrectly = {
	path: '/useTalentCorrectly',
	component: Layout,
	name: 'UseTalentCorrectly',
	meta: {
		title: '用对人',
		icon: 'talentassessment'
	},
	redirect: '/useTalentCorrectly/talentMap',
	children: [
		{
			path: 'talentEvaluation',
			name: 'TalentEvaluation',
			meta: {
				title: '人才评估'
			},
			component: Noop,
			children: [
				{
					path: 'evaluationPlan',
					name: 'EvaluationPlan',
					component: () => import('@/views/talent-assessment/evaluationPlan/index.vue'),
					meta: {
						title: '评估计划'
					}
				},
				{
					path: 'create',
					name: 'CreateAssessPlan',
					component: () => import('@/views/talent-assessment/evaluationPlan/createPlan.vue'),
					meta: {
						title: '创建评估计划'
					}
				},
				{
					path: 'evaluationCenter',
					name: 'EvaluationCenter',
					component: () => import('@/views/talent-assessment/evaluationCenter/index.vue'),
					meta: {
						title: '评估中心'
					}
				},
				{
					path: 'evaluationResult',
					name: 'EvaluationResult',
					component: () => import('@/views/talent-assessment/evaluationResult/index.vue'),
					meta: {
						title: '评估结果'
					}
				},
				{
					path: 'appraiserManagement',
					name: 'AppraiserManagement',
					component: () => import('@/views/talent-assessment/assessmentResult/index.vue'),
					meta: {
						title: '评估师管理'
					}
				},
				{
					path: 'reportSettings',
					name: 'ReportSettings',
					component: () => import('@/views/talent-assessment/reportSettings/index.vue'),
					meta: {
						title: '个人报告设置'
					}
				}
				// {
				// 	path: 'evaluationDetail',
				// 	name: 'EvaluationDetail',
				// 	component: () => import('@/views/talent-assessment/evaluationCenter/detail.vue'),
				// 	meta: {
				// 		title: '评估详情'
				// 	}
				// }
			]
		},
		// {
		// 	path: 'talentMap',
		// 	name: 'TalentMap',
		// 	meta: {
		// 		title: '人才地图'
		// 	},
		// 	component: () => import('@/views/talent-assessment/list/map')
		// },
		// {
		// 	path: 'assessmentCenter',
		// 	name: 'AssessmentCenter',
		// 	// redirect: '/talentassessment/assessmentCenter/list',
		// 	component: Noop,
		// 	meta: {
		// 		title: '评估中心'
		// 	},
		// 	children: [
		// 		{
		// 			path: '/',
		// 			name: 'AssessmentCenter',
		// 			hidden: true,
		// 			component: () => import('@/views/talent-assessment/assessmentCenter'),
		// 			meta: {
		// 				title: '评估列表'
		// 			}
		// 		},
		// 		{
		// 			path: 'setting',
		// 			name: 'AssessmentSetting',
		// 			hidden: true,
		// 			// redirect: '/talentassessment/talentassessmentcenter/list',
		// 			component: () => import('@/views/talent-assessment/setting/index'),
		// 			meta: {
		// 				title: '设置'
		// 			}
		// 		},
		// 		{
		// 			path: 'add',
		// 			name: 'AddTalent',
		// 			hidden: true,
		// 			component: () => import('@/views/talent-assessment/add/index'),
		// 			meta: {
		// 				title: '添加人才'
		// 			}
		// 		},
		// 		{
		// 			path: 'create',
		// 			name: 'CreatePlan',
		// 			hidden: true,
		// 			component: () => import('@/views/talent-assessment/assessmentCenter/add'),
		// 			meta: {
		// 				title: '创建评估计划'
		// 			}
		// 		}
		// 	]
		// },
		// {
		// 	path: '/talentAssessment',
		// 	component: Noop,
		// 	meta: {
		// 		title: '人才评估'
		// 	},
		// 	redirect: '/talentAssessment/assessmentCenter',
		// 	children: [
		// 		{
		// 			path: 'assessmentCenter',
		// 			name: 'AssessmentCenter',
		// 			redirect: '/talentassessment/assessmentCenter/list',
		// 			component: () => import('@/views/talent-assessment/index'),
		// 			meta: {
		// 				title: '评估中心'
		// 			},
		// 			children: [
		// 				{
		// 					path: '/',
		// 					name: 'List',
		// 					hidden: true,
		// 					component: () => import('@/views/talent-assessment/list/list'),
		// 					meta: {
		// 						title: '评估列表'
		// 					}
		// 				},
		// 				{
		// 					path: 'setting',
		// 					name: 'Setting',
		// 					hidden: true,
		// 					// redirect: '/talentassessment/talentassessmentcenter/list',
		// 					component: () => import('@/views/talent-assessment/setting/index'),
		// 					meta: {
		// 						title: '设置'
		// 					}
		// 				},
		// 				{
		// 					path: 'add',
		// 					name: 'add',
		// 					hidden: true,
		// 					// redirect: '/talentassessment/talentassessmentcenter/list',
		// 					component: () => import('@/views/talent-assessment/add/index'),
		// 					meta: {
		// 						title: '添加'
		// 					}
		// 				}
		// 			]
		// 		},
		// 		{
		// 			path: 'assessmentResult',
		// 			name: 'AssessmentResult',
		// 			meta: {
		// 				title: '评估结果'
		// 			},
		// 			component: () => import('@/views/talent-assessment/list/result')
		// 		}
		// 	]
		// },
		{
			path: '/theCadreAssessment',
			component: Noop,
			redirect: '/theCadreAssessment/list',
			children: [
				{
					path: 'list',
					name: 'theCadreAssessmentlist',
					meta: {
						title: '干部评估'
					},
					component: null
				}
			]
		},
		coreHuman
	]
};
export default useTalentCorrectly;
