/*
 * @Author: 卢均锐morye
 * @Date: 2020-04-28 09:40:03
 * @LastEditTime: 2020-04-29 18:01:08
 *
 * 菜单渲染说明：
 * src\layout\components\Sidebar\index.vue 该文件为左侧菜单栏相关文件
 * src\layout\components\Sidebar\SidebarItem.vue 该文件为菜单渲染相关文件
 */
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
import organizingpersonnel from './modules/organizingpersonnel';
import doRightThings from './modules/doRightThings';
import useTalentCorrectly from './modules/useTalentCorrectly';
// import manpowerPlanning from "./modules/manpowerPlanning";
import recruitment_allocation from './modules/recruitment_allocation';
// import achievementsManagement from "./modules/achievementsManagement"; //morye
// import remunerationManagement from './modules/remunerationManagement' //morye
// import trainMangement from "./modules/trainMangement"; //morye
import overview from './modules/overview'; //morye
// import index from './modules/index'
// import coreHuman from "./modules/corehuman";
import systemConfig from './modules/system-config';
// import talentAssessment from './modules/talent-assessment';
import companys from './modules/companys';
// import peoplepostmatch from './modules/peopelPostMatch';
import approval from './modules/audit';
import wap from './modules/wap';

import distributionOfWelfare from './modules/distributionOfWelfare';
// import attendanceManage from "./modules/attendanceManage";
import ResultInfo from '@/views/peoplePostMatch/result/resultInfo';
// import employees from './modules/employees'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import filter from './filter';
NProgress.inc(0.2);
NProgress.configure({ easing: 'ease', speed: 2000, showSpinner: false, trickle: false });

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
Router.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};
const router = new Router({
	scrollBehavior: () => ({
		y: 0
	}),
	routes: [
		{
			path: '/',
			component: Layout,
			redirect: '/workbench',
			meta: {
				title: 'index',
				icon: 'index'
			},
			children: [
				{
					path: '/index',
					component: () => import('@/views/index/index')
				}
			]
		},
		// {
		//     path: "/peoplepostmatch",
		//     component: Layout,
		//     redirect: "/peoplepostmatch/index",
		//     meta: {
		//         title: "peoplepostmatch",
		//         icon: "人力规划@1x",
		//     },
		//     children: [{
		//         path: '/peoplepostmatch/index',
		//         name: "peoplePostMatch",
		//         component: () =>
		//             import('@/views/peoplePostMatch/list'),
		//     }]
		// },
		{
			path: '/companys',
			component: Layout,
			redirect: '/companys/index',
			meta: {
				title: 'companys',
				icon: '企业文化@1x'
			},
			children: [
				{
					path: '/companys/index',
					name: 'companys',
					// meta:{requireAuth: true},
					component: () => import('@/views/companys/list')
				}
			]
		},
		{
			path: '/approval',
			component: Layout,
			redirect: '/approval/index',
			name: 'ApprovalManagement',
			meta: {
				title: 'approval',
				icon: '审批 @1x'
			},
			children: [
				{
					path: 'index',
					name: 'ApprovalManagement',
					// name: 'companyList',
					// meta:{requireAuth: true},
					component: () => import('@/views/approval/list')
				}
			]
		},
		{
			path: '/404',
			component: Layout,
			children: [
				{
					path: '/',
					name: '404',
					// meta:{requireAuth: true},
					component: () => import('@/views/404/index')
				}
			]
		},
		{
			path: '/login',
			component: () => import('@/views/login/index-copy'),
			// import ('@/views/login/index'),
			hidden: true,
			name: 'login'
		},
		{
			path: '/workbench',
			component: () => import('@/views/login/homePage.vue'),
			name: 'workbench'
		},
		{
			path: 'powerinfo',
			name: 'PowerplanInfo',
			hidden: true,
			component: () => import('@/views/manpowerplanning/mpPlan/info'),
			// import ('@/views/developping/index'),
			meta: {
				title: '详情'
			}
		},
		{
			path: '/powerDetail',
			name: 'PowerplanDetail',
			hidden: true,
			component: () => import('@/views/manpowerplanning/mpPlan/planDetail'),
			// import ('@/views/developping/index'),
			meta: {
				title: '详情'
			}
		},
		{
			path: '/powerresultDetail',
			name: 'PowerResultDetail',
			hidden: true,
			component: () => import('@/views/manpowerplanning/mpResult/resultDetail'),
			// import ('@/views/developping/index'),
			meta: {
				title: '详情'
			}
		},
		// index,
		// 平台概况
		// overview,
		// 基础人事
		organizingpersonnel,
		// 做对事
		doRightThings,
		// 用对人
		useTalentCorrectly,

		// // 人才评估
		// talentAssessment,
		// 人岗匹配
		// peoplepostmatch,
		// 人力规划
		// manpowerPlanning,
		// 招聘调配
		// recruitment_allocation,
		// 绩效管理
		// achievementsManagement,
		// 薪酬管理
		// remunerationManagement,
		// // 核心人力
		// coreHuman,
		// 教育培训
		// trainMangement,
		{
			path: '/recruitmentpostmanagement:type/:id',
			name: 'Addrecruitmentpostmanagement',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/recruitmentpostmanagement/add'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'add'
			}
		},
		// audit,
		// // 考勤管理
		// attendanceManage,
		// 分好钱
		distributionOfWelfare,
		// 审批管理
		// approval,
		// 权限管理
		systemConfig,
		// 系统管理
		companys,
		// employees,
		{
			path: '/achievements/detail',
			name: 'AchievementsDetail',
			hidden: true,
			component: () => import('@/views/achievementsmanagement/assessmentDetail'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/organizeMap',
			name: 'OrganizeMap',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/organizing/organizational/organizeMap.vue'),
			meta: {
				title: '组织架构图'
			}
		},
		{
			path: '/achievements/confirm/detail',
			name: 'AchievementsConfirmDetail',
			hidden: true,
			component: () => import('@/views/achievementsmanagement/confirmDetail'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/achievements/setting/index',
			name: 'AchievementsSettingIndex',
			hidden: true,
			component: () => import('@/views/achievementsmanagement/setting/index'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/achievements/create',
			name: 'AchievementsCreate',
			hidden: true,
			component: () => import('@/views/achievementsmanagement/create'),
			meta: {
				title: 'export'
			}
		},
		{
			//morye
			path: '/remuneration/annual/preview',
			name: 'RemunerationAnnualPreview',
			hidden: true,
			component: () => import('@/views/remuneration/annual/preview'),
			meta: {
				title: 'export'
			}
		},
		{
			//morye
			path: '/train/detail',
			name: 'TrainDetail',
			hidden: true,
			component: () => import('@/views/trainMange/trainDetail'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/exportResume',
			name: 'ExportResume',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/resumelibrary/export'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/scheduleInterview/:resumeId',
			name: 'ScheduleInterview',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/resumelibrary/scheduleInterview'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/interviewInfo',
			name: 'InterviewInfo',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/interviewmanagement/add'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/registrationform',
			name: 'RegistrationForm',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/interviewmanagement/table')
		},
		{
			path: '/admissioninfo',
			name: 'AdmissionInfo',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/admissionmanagement/add'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/matchPlan/plan',
			name: 'MatchplanInfo',
			hidden: true,
			component: () => import('@/views/peoplePostMatch/plan/info'),
			// import ('@/views/developping/index'),
			meta: {
				title: '详情'
			}
		},
		{
			path: 'matchPlan/detail',
			name: 'MatchplanDetail',
			hidden: true,
			component: () => import('@/views/peoplePostMatch/plan/planInfo'),
			// import ('@/views/developping/index'),
			meta: {
				title: '详情'
			}
		},
		// {

		// },
		{
			path: '/admissionapply',
			name: 'AdmissionApply',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/admissionmanagement/apply'),
			meta: {
				title: 'export'
			}
		},
		{
			path: '/enterresume',
			name: 'EnterResume',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/resumelibrary/entering'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'enterresume'
			}
		},
		{
			path: '/editresume/:id',
			name: 'EditResume',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/resumelibrary/entering'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'enterresume'
			}
		},
		{
			path: '/resumeinfo/:id',
			name: 'ResumeInfo',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/resumelibrary/entering'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'enterresume'
			}
		},
		{
			//morye
			path: '/distinguish',
			name: 'Distinguish',
			hidden: true,
			component: () => import('@/views/recruitment_allocation/resumelibrary/distinguish'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'distinguish'
			}
		},
		{
			path: '/recruitprint/:id',
			name: 'RecruitPrint',
			hidden: true,
			component: () => import('@/components/recruitPrint/index')
		},
		{
			path: '/matchAssess',
			name: 'peopelPostMatch',
			hidden: true,
			component: () => import('@/views/peoplePostMatch/assess/index'),
			meta: {
				title: 'assess'
			}
		},
		{
			path: '/matchAudit',
			name: 'peopelPostMatchAudit',
			hidden: true,
			component: () => import('@/views/peoplePostMatch/audit/index'),
			meta: {
				title: 'audit_detail'
			}
		},
		{
			path: '/matchAuditDetail',
			name: 'matchAuditDetail',
			hidden: true,
			component: () => import('@/views/peoplePostMatch/matchAuditDetail/index'),
			meta: {
				title: 'assess_detail'
			}
		},
		{
			path: '/audit_info',
			name: 'matchAuditDetail',
			hidden: true,
			component: () => import('@/views/approval/auditList/auditInfo'),
			meta: {
				title: 'auditInfo'
			}
		},
		{
			path: '/result_info',
			name: 'resultInfo',
			hidden: true,
			component: ResultInfo,
			meta: {
				title: 'resultInfo'
			}
		},
		{
			path: '/currentInfo',
			name: 'currentInfo',
			hidden: true,
			component: () => import('@/views/remuneration/monthMoney/monthInfo')
		},
		{
			path: '/formStep',
			name: 'formStep',
			hidden: true,
			component: () => import('@/views/approval/auditManage/formStep'),
			meta: {
				title: 'formStep'
			}
		},
		{
			path: '/enterprisecostallocation',
			name: 'Enterprisecostallocation',
			hidden: true,
			component: () => import('@/views/remuneration/remunerationSetting/enterprisecostallocation')
		},
		{
			path: '/prisecostallocationInfo',
			name: 'PrisecostallocationInfo',
			hidden: true,
			component: () => import('@/views/remuneration/remunerationSetting/prisecostallocationInfo')
		},
		{
			path: '/inditDetail',
			name: 'inditDetail',
			hidden: true,
			component: () => import('@/views/peoplePostMatch/indittDetail/index'),
			meta: {
				title: 'inditDetail'
			}
		},
		{
			path: '/assess',
			name: 'TalentAssessment',
			hidden: true,
			component: () => import('@/views/talent-assessment/assess/index'),
			meta: {
				title: 'assess'
			}
		},
		{
			path: '/assess_detail',
			name: 'TalentAssessmentDetail',
			hidden: true,
			component: () => import('@/views/talent-assessment/audit-detail/index'),
			meta: {
				title: 'assess_detail'
			}
		},
		{
			path: '/audit',
			name: 'Audit',
			hidden: true,
			component: () => import('@/views/talent-assessment/audit/index'),
			meta: {
				title: 'audit'
			}
		},
		{
			path: '/audit_detail',
			name: 'AuditDetail',
			hidden: true,
			component: () => import('@/views/talent-assessment/audit-detail/index'),
			meta: {
				title: 'audit_detail'
			}
		},
		{
			path: '/user_info',
			name: 'UserInfo',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/humanaffairs/info'),
			meta: {
				title: 'user_info'
			}
		},
		{
			path: '/deleterecord',
			hidden: true,
			name: 'DeleteRecord',
			component: () => import('@/views/organizingpersonnel/humanaffairs/deleterecord')
		},
		{
			path: '/addhumanaffairs',
			name: 'Addhumanaffairs',
			// component: () => import('@/components/humanafairs/addhumanaffairs')
			component: () => import('@/views/organizingpersonnel/humanaffairs/information/personnel/personEntry.vue')
		},
		{
			path: '/reward_info',
			name: 'rewardInfo',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/humanaffairs/rewardInfo'),
			meta: {
				title: 'user_info'
			}
		},
		{
			path: '/dimission_info',
			name: 'dimissionInfo',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/humanaffairs/dimissionInfo'),
			meta: {
				title: 'dimission_info'
			}
		},
		{
			path: '/positive_info',
			name: 'positiveInfo',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/humanaffairs/positiveInfo'),
			meta: {
				title: 'positive_info'
			}
		},
		{
			path: '/promotion_edit',
			name: 'promotionEdit',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/humanaffairs/promotionInfo'),
			meta: {
				title: 'user_info'
			}
		},
		{
			path: '/personalInfo',
			name: 'personalInfo',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/humanaffairs/imgInfo'),
			meta: {
				title: 'user_info'
			}
		},
		{
			path: '/companyInfo',
			name: 'companyInfo',
			hidden: true,
			component: () => import('@/views/organizingpersonnel/humanaffairs/imgInfo'),
			meta: {
				title: 'user_info'
			}
		},
		{
			path: '/register',
			name: 'Register',
			hidden: true,
			component: () => import('@/views/register/register'),
			meta: {
				title: 'register'
			}
		},
		{
			path: '/roleInfo',
			name: 'roleInfo',
			hidden: true,
			component: () => import('@/views/system-config/rights-profile/roleInfo'),
			meta: {
				title: 'user_info'
			}
		},
		{
			path: '/orgPrinInfo',
			name: 'orgPrinInfo',
			hidden: true,
			component: () => import('@/views/system-config/orgPrinConfig/orgPrinInfo'),
			meta: {
				title: 'user_info'
			}
		},
		{
			path: '/coreHuman/:coreManpowerApprovalId',
			name: 'applyInfo',
			hidden: true,
			component: () => import('@/views/corehuman/corehumanapply'),
			meta: {
				title: 'Apply'
			}
		},
		{
			path: '/coreApply',
			name: 'CoreApply',
			hidden: true,
			component: () => import('@/views/corehuman/corehumanapply')
		},
		{
			path: '/humancore/:coreManpowerApprovalId',
			name: 'checkInfo',
			hidden: true,
			component: () => import('@/views/corehuman/corehumanapply')
		},
		{
			path: '/adjustcorehuman/:coreManpowerApprovalId',
			name: 'corehumanAdjust',
			hidden: true,
			component: () => import('@/views/corehuman/corehumanapply')
		},
		{
			path: '/reportcorehuman/:coreManpowerApprovalId',
			name: 'reportInfo',
			hidden: true,
			component: () => import('@/views/corehuman/corehumanapply')
		},
		wap,
		{
			path: '/staff',
			name: 'staff',
			hidden: true,
			component: () => import('@/views/staffSystem'),
			redirect: '/staff/approval',
			children: [
				{
					path: '/staff/approval',
					name: 'StaffApproval',
					component: () => import('@/views/staffSystem/approval')
				},
				{
					path: '/staff/info',
					name: 'StaffInfo',
					component: () => import('@/views/staffSystem/info')
				},
				{
					path: '/staff/match_position',
					component: () => import('@/views/staffSystem/match_position')
				},
				{
					path: '/staff/talent_planning',
					component: () => import('@/views/staffSystem/talent_planning')
				},
				{
					path: '/staff/recruit',
					component: () => import('@/views/staffSystem/recruit')
				},
				{
					path: '/staff/momey',
					component: () => import('@/views/staffSystem/money')
				},
				{
					path: '/staff/achievements',
					component: () => import('@/views/staffSystem/achievements')
				},
				{
					//morye
					path: '/staff/trainMange',
					component: () => import('@/views/staffSystem/trainMange/index')
				},
				{
					path: '/staff/corehuman',
					component: () => import('@/views/staffSystem/corehuman')
				},
				{
					//morye
					path: '/staff/cc',
					component: () => import('@/views/staffSystem/ccInformation/index')
				}
			]
		},
		{
			path: '/staff/organizingAchievements',
			name: 'OrganizingAchievements',
			component: () => import('@/views/staffSystem/organizingAchievements')
		},
		{
			path: '/staff/assesstutorshipInfo/:staffId',
			name: 'AssesstutorshipInfo',
			hidden: true,
			component: () => import('@/views/staffSystem/assesstutorshipInfo')
		},
		{
			path: '/staff/talent_aduit',
			hidden: true,
			component: () => import('@/views/staffSystem/planning_aduit')
		},
		{
			path: '/staff/approval_details',
			name: 'ApprovalDetails',
			hidden: true,
			component: () => import('@/views/staffSystem/approval_details')
		},
		{
			path: '/staff/approval_apply',
			name: 'ApplyApproval',
			component: () => import('@/views/staffSystem/approval_apply.vue')
			// component: () => import('@/views/staffSystem/applyApproval/apply')
		},
		// 申请 页面
		/* 组织 */
		{
			path: '/staff/approvalApply',
			hidden: true,
			component: () => import('@/views/staffSystem/applyApproval/apply')
		},
		{
			path: '/staff/orgApply',
			hidden: true,
			component: () => import('@/views/staffSystem/applyApproval/orgApply/orgSet')
		},
		{
			path: '/staff/orgSubmit',
			hidden: true,
			component: () => import('@/views/staffSystem/applyApproval/orgApply/orgSubmit')
		},
		/* 调整申请 */
		{
			path: '/staff/changeApply',
			name: 'ChangeApply',
			component: () => import('@/views/staffSystem/applyApproval/planningApply/mpApply.vue')
		},
		{
			path: '/staff/print',
			hidden: true,
			component: () => import('@/views/staffSystem/approval_details')
		},
		{
			path: '/staff/judge/:type',
			hidden: true,
			component: () => import('@/views/staffSystem/judge')
		},
		{
			path: '/staff/salary/details',
			name: 'staffSalaryDetails',
			hidden: true,
			component: () => import('@/views/remuneration/monthMoney/monthInfo')
		},
		{
			path: '/staff/recruit/interviewappraisal/:processId:arrangeId/:name',
			name: 'interviewAppraisal',
			hidden: true,
			component: () => import('@/views/staffSystem/interviewappraisal')
		},
		{
			path: '/staff/recruit/interviewappraisalInfo',
			name: 'interviewAppraisalInfo',
			hidden: true,
			component: () => import('@/views/staffSystem/interviewappraisal')
		},
		{
			path: '/useTalentCorrectly/talentEvaluation/evaluationDetail',
			name: 'EvaluationDetail',
			component: () => import('@/views/talent-assessment/evaluationCenter/detail.vue'),
			meta: {
				title: '评估详情'
			}
		},
		{
			path: '/evaluateTalents',
			name: 'EvaluateTalents',
			meta: {
				title: '人才评估'
			},
			component: () => import('@/views/staffSystem'),
			children: [
				{
					path: '/',
					name: 'EvaluateTalents',
					component: () => import('@/views/evaluateTalents/index.vue')
				}
			]
		},
		{
			path: '/dictionaryConfig',
			name: 'DictionaryConfig',
			meta: {
				title: '字典项配置'
			},
			component: Layout,
			children: [
				{
					path: '/',
					name: 'DictionaryConfig',
					component: () => import('@/views/system-config/dictionaryConfig/index.vue')
				}
			]
		},
		{
			path: '/pptTemplateSet',
			name: 'PptTemplateSet',
			meta: {
				title: '模板配置'
			},
			component: () => import('@/views/pptist/pptTemplateSet.vue')
		},
		{
			path: '/useTalentCorrectly/talentEvaluation/report',
			name: 'ReportPptView',
			meta: {
				title: '查看ppt'
			},
			component: Layout,
			children: [
				{
					path: '/',
					name: 'ppt查看',
					component: () => import('@/views/pptist/index.vue')
				}
			]
		},
		{
			path: '/projectPptReport',
			name: 'ReportPptView',
			meta: {
				title: '查看编辑ppt'
			},
			component: () => import('@/views/staffSystem'),
			children: [
				{
					path: '/',
					name: '查看编辑ppt',
					component: () => import('@/views/pptist/index.vue')
				}
			]
		},
		{
			path: '/evaluationReportView',
			name: 'EvaluationReportView',
			component: () => import('@/views/talent-assessment/evaluationCenter/evaluationReportView.vue')
		},
		{
			path: '/recordDetail',
			name: 'RecordDetail',
			component: () => import('@/views/talent-assessment/evaluationCenter/recordDetail.vue')
		},
		{
			path: '*',
			redirect: '/404',
			hidden: true
		}
	]
});

router.beforeEach(async (to, from, next) => {
	NProgress.start();
	await filter(to, next);
});
router.afterEach(async (to) => {
	NProgress.done();
});
export default router;
