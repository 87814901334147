<template>
  <section class="app-main" id="appMain">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.fullPath;
    }
  }
};
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 250px);
  min-width: calc(100vw - 300px);
  /* width: 100%; */
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  margin: 30px;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 #DEE1E7;
}
.fixed-header+.app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
