import { EXISTAPPROVALPENDING, ACHIEVEMENTSLOADING } from '../types';

const state = {
	existApprovalPending: false,
	achievementsloading: false,
	applyTypeList: []
};
const mutations = {
	[EXISTAPPROVALPENDING](state, action) {
		state.existApprovalPending = action;
	},
	[ACHIEVEMENTSLOADING](state, action) {
		state.achievementsloading = action;
	},
	SET_APPLY_TYPE(state, action) {
		state.applyTypeList = action;
	}
};
export default {
	state,
	mutations
};
