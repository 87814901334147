export default {
	inserted(el, binding) {
		console.log(el, 'elelelelelelelelelel');
		// const selectWrapDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
		// selectWrapDom.addEventListener('scroll', function () {
		// 	const condition = this.scrollHeight - this.scrollTop <= this.clientHeight + 20;
		// 	if (condition) {
		// 		binding.value.callback(...(binding.value.args || []));
		// 	}
		// });
		el.addEventListener('change', function (e) {
			console.log(e.target, 'eeeeeeeeeeeeeee');
		});
	}
};
