<template>
	<div class="navbar">
		<hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" v-if="!showType" />
		<div class="hamburger-container" style="width: 168px" v-if="showType === '1'" @click="toHome" title="返回首页">
			<svg-icon style="width: 168px; padding: 14px 0; height: 100%" icon-class="registerLogo"></svg-icon>
		</div>
		<div class="right-menu">
			<span style="vertical-align: text-bottom; font-size: 14px">欢迎您，{{ userInfo && userInfo.nickName }}</span
			>&nbsp;&nbsp;
			<el-dropdown class="avatar-container" trigger="click">
				<div class="avatar-wrapper">
					<img :src="userInfo.avatarUrl" class="user-avatar" />
					<i class="el-icon-caret-bottom" />
				</div>
				<el-dropdown-menu slot="dropdown" class="user-dropdown">
					<el-dropdown-item>
						<span style="display: block" @click="setModalVisible = true">账号设置</span>
					</el-dropdown-item>
					<el-dropdown-item>
						<span style="display: block" @click="dialogFormVisible = true">修改密码</span>
					</el-dropdown-item>
					<el-dropdown-item v-if="comId !== '1' && $store.state.app.userType == '1'">
						<span style="display: block" @click="cancelFormVisible = true">注销账号</span>
					</el-dropdown-item>
					<el-dropdown-item divided>
						<span style="display: block" @click="logout">退出登录</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<el-dialog title="账号设置" :visible.sync="setModalVisible" width="30%" append-to-body :close-on-click-modal="false" center>
				<el-form :model="setForm" label-position="left" :rules="setFormRule" ref="setForm" label-width="58px">
					<el-form-item prop="name" label="姓名">
						<el-col :span="24">
							<el-input v-model="setForm.name" type="text" autocomplete="off"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item prop="emial" label="邮箱">
						<el-col :span="24">
							<el-input v-model="setForm.emial" type="text" autocomplete="off"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item label="手机" class="pl">
						<el-col :span="24" style="display: flex">
							<el-input
								style="width: 70%; margin-right: 15px"
								:disabled="true"
								v-model.number="setForm.phone"
								type="text"
								autocomplete="off"
							></el-input>
							<el-button style="border: none" @click="updatePhone" type="primary">修改手机号</el-button>
						</el-col>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="setModalVisible = false">取消</el-button>
					<el-button type="primary" @click="updateUser">确定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="30%" @close="handleChangPwdClose" center append-to-body>
				<el-form :model="form" label-position="right" :rules="changePwdRule" ref="form">
					<el-form-item prop="oldPassword" label="旧密码" :label-width="formLabelWidth">
						<el-col :span="24">
							<el-input v-model="form.oldPassword" placeholder="当前密码" type="password" autocomplete="off"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item prop="newPassword" label="新密码" :label-width="formLabelWidth">
						<el-col :span="24">
							<el-input
								v-model="form.newPassword"
								placeholder="请输入8-20位字符，必须包含字母和数字"
								type="password"
								autocomplete="off"
							></el-input>
						</el-col>
					</el-form-item>
					<el-form-item prop="againPassword" label="确认密码" :label-width="formLabelWidth">
						<el-col :span="24">
							<el-input v-model="form.againPassword" type="password" placeholder="请再次输入密码" autocomplete="off"></el-input>
						</el-col>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="handleChangPwdClose">取消</el-button>
					<el-button type="primary" @click="changepwd">确定</el-button>
				</div>
			</el-dialog>
			<el-dialog
				title="修改手机号"
				:visible.sync="phoneFormVisible"
				width="35%"
				center
				@close="handleChangeClose"
				:close-on-click-modal="false"
				append-to-body
			>
				<el-form :model="changePhoneForm" label-position="left" :rules="changePhoneRule" ref="changePhoneForm">
					<el-form-item label="手机号" :label-width="formLabelWidth">
						<el-col :span="17">
							<div class="inputDiv">+{{ changePhoneForm.oldAreaCode }}{{ changePhoneForm.oldPhoneNumber }}</div>
						</el-col>
						<el-col :span="6" style="margin-left: 15px">
							<el-button @click="send" type="primary" :disabled="(disabled = !show)">
								<span v-show="show">获取验证码</span>
								<span v-show="!show" class="count">{{ count }} s可重发</span>
							</el-button>
						</el-col>
					</el-form-item>
					<el-form-item prop="oldPhoneCode" label="验证码" :label-width="formLabelWidth">
						<el-col :span="23">
							<el-input
								v-model="changePhoneForm.oldPhoneCode"
								placeholder="请输入原手机号验证码"
								type="text"
								autocomplete="off"
							></el-input>
						</el-col>
					</el-form-item>
					<el-form-item prop="newPhoneNumber" label="新手机号" :label-width="formLabelWidth">
						<el-col :span="17">
							<el-input v-model.number="changePhoneForm.newPhoneNumber" autocomplete="off">
								<el-select style="width: 85px" v-model="changePhoneForm.newAreaCode" slot="prepend" placeholder="请选择">
									<el-option label="+86" value="86"></el-option>
									<el-option label="+852" value="852"></el-option>
									<el-option label="+853" value="853"></el-option>
									<el-option label="+886" value="886"></el-option>
								</el-select>
							</el-input>
						</el-col>
						<el-col :span="6" style="margin-left: 15px">
							<el-button @click="newPhoneSend" type="primary" :disabled="!newShow || String(changePhoneForm.newPhoneNumber).length < 4">
								<span v-show="newShow">获取验证码</span>
								<span v-show="!newShow" class="count">{{ newCount }} s可重发</span>
							</el-button>
						</el-col>
					</el-form-item>
					<el-form-item prop="newPhoneCode" label="验证码" :label-width="formLabelWidth">
						<el-col :span="23">
							<el-input v-model="changePhoneForm.newPhoneCode" placeholder="请输入新手机号验证码" autocomplete="off"></el-input>
						</el-col>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="handleChangeClose">取消</el-button>
					<el-button type="primary" @click="changePhone">确定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="验证身份" :visible.sync="cancelFormVisible" @close="handleDestroyClose" width="30%" center append-to-body>
				<el-form :model="cancelForm" :rules="cancelRule" ref="cancelForm">
					<el-form-item>
						<el-alert
							style="height: 30px; width: 95%"
							title="注销账号成功后，不可恢复，谨慎操作"
							type="info"
							:closable="false"
							show-icon
						></el-alert>
					</el-form-item>
					<el-form-item label="手机号" required>
						<el-row>
							<el-col :span="12">
								<div class="inputDiv">+{{ cancelForm.areaCode }}{{ cancelForm.phoneNum }}</div>
							</el-col>
							<el-col :span="7" style="text-align: right">
								<el-button @click="destroySend" type="primary" class="sendBtn" :disabled="(disabled = !destroyShow)">
									<span v-show="destroyShow">获取验证码</span>
									<span v-show="!destroyShow" class="count">{{ destroyCount }} s可重发</span>
								</el-button>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item prop="checkCode" label="验证码">
						<el-col :span="19">
							<el-input v-model="cancelForm.checkCode" autocomplete="off"></el-input>
						</el-col>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="handleDestroyClose">取消</el-button>
					<el-button type="primary" @click="destroy">确定</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import Hamburger from './Hamburger';
import { mapGetters } from 'vuex';
import { Logout } from '@/api/common';
import md5 from 'js-md5';
import * as util from '@/utils/util';
import * as api from '@/api/loginInfo';
const TIME_COUNT = 60;

export default {
	props: { showType: { type: String } },
	components: {
		Hamburger
	},
	data() {
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.form.newPassword) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};

		const equalOld = (rule, value, callback) => {
			if (value === this.form.oldPassword) {
				callback(new Error('新密码不能和旧密码相同'));
			} else if (!value.match(/^(?![^a-zA-Z]+$)(?!\D+$)/)) {
				callback(new Error('请输入8-20位字符，必须包含字母和数字'));
			} else if (value.length < 8 || value.length > 20) {
				callback(new Error('请输入8-20位字符，必须包含字母和数字'));
			} else {
				callback();
			}
		};

		return {
			comId: null,
			setModalVisible: false,
			dialogFormVisible: false,
			phoneFormVisible: false,
			cancelFormVisible: false,
			show: true,
			count: '', // 初始化次数
			timer: null,
			newTimer: null,
			newShow: true,
			newCount: '',
			destroyTimer: null,
			destroyCount: '',
			destroyShow: true,
			userInfo: [],
			setForm: {
				name: '',
				emial: '',
				phone: '',
				areaCode: ''
			},
			form: {
				oldPassword: '',
				newPassword: '',
				againPassword: ''
			},
			changePhoneForm: {
				oldPhoneNumber: '',
				oldPhoneCode: '',
				newPhoneNumber: '',
				newPhoneCode: '',
				oldAreaCode: '86',
				newAreaCode: '86'
			},
			cancelForm: {
				phoneNum: '',
				checkCode: '',
				password: '',
				areaCode: '86'
			},
			formLabelWidth: '90px',
			setFormRule: {
				name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
				emial: [
					{ required: true, message: '请输入邮箱', trigger: 'blur' },
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
				],
				phone: [
					{
						type: 'number',
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					},
					{
						pattern: /^\d{3,11}$|^\d{3,11}$/,
						message: '请输入正确手机号码',
						trigger: 'blur'
					}
				]
			},
			changePwdRule: {
				oldPassword: [{ required: true, message: '请输入当前密码', trigger: 'blur' }],
				newPassword: [
					{ required: true, message: '请输入新密码', trigger: 'blur' },
					{ validator: equalOld, trigger: 'blur' }
				],
				againPassword: [{ required: true, validator: validatePass2, trigger: 'blur' }]
			},
			changePhoneRule: {
				oldPhoneNumber: [
					{
						type: 'number',
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					},
					{
						pattern: /^\d{3,11}$|^\d{3,11}$/,
						message: '请输入正确手机号码',
						trigger: 'blur'
					}
				],
				newPhoneNumber: [
					{
						type: 'number',
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					},
					// { minlength: 3, maxlength: 11, message: '请输入正确手机号码', trigger: 'blur' }
					{
						pattern: /^\d{3,11}$|^\d{3,11}$/,
						message: '请输入正确手机号码',
						trigger: 'blur'
					}
				],
				oldPhoneCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
				newPhoneCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
			},
			cancelRule: {
				checkCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
			}
		};
	},
	computed: {
		...mapGetters(['sidebar'])
	},
	mounted() {
		this.$nextTick(() => {
			if (util.getCookie('token')) {
				this.getUserInfo();
			}
		});
		console.log(this.$store.state.app.userType);
	},
	methods: {
		toggleSideBar() {
			this.$store.dispatch('app/toggleSideBar');
		},
		logout() {
			this.$confirm('您是否确认退出登录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					await Logout({ request: {} });
					util.delCookie('token');
					sessionStorage.setItem('comName', '');
					localStorage.setItem('comId', '');
					// this.$store.dispatch("app/delPermission");
					this.$router.push('/login');
				})
				.catch(() => {});
		},
		updatePhone() {
			this.phoneFormVisible = true;
			this.changePhoneForm.oldAreaCode = this.setForm.areaCode;
			this.changePhoneForm.oldPhoneNumber = this.setForm.phone;
		},
		delteUser() {
			this.cancelFormVisible = true;
		},
		updateUser() {
			this.$refs['setForm'].validate((valid) => {
				if (valid) {
					api.infoUpdate({
						request: {
							nickName: this.setForm.name,
							mailbox: this.setForm.emial
						}
					}).then((res) => {
						if (res.code == 0) {
							this.setModalVisible = false;
							this.getUserInfo();
						}
					});
				}
			});
		},
		handleChangPwdClose() {
			this.$refs['form'].clearValidate();
			this.$refs['form'].resetFields();
			this.dialogFormVisible = false;
		},
		changepwd() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					api.changePwd({
						request: {
							oldPassword: md5(this.form.oldPassword),
							newPassword: md5(this.form.newPassword),
							againPassword: md5(this.form.againPassword)
						}
					}).then((res) => {
						if (res.code == 0) {
							this.$message.success('密码修改成功');
							this.handleChangPwdClose();
							Logout({ request: {} });
							util.delCookie('token');
							this.$router.push('/login');
						}
					});
				}
			});
		},
		handleDestroyClose() {
			this.$refs['cancelForm'].clearValidate();
			this.$refs['cancelForm'].resetFields();
			this.cancelFormVisible = false;
		},
		destroy() {
			this.$refs['cancelForm'].validate((valid) => {
				if (valid) {
					this.$confirm('您是否确定注销账号?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
						.then(async () => {
							api.destroy({
								request: {
									checkCode: this.cancelForm.checkCode,
									phoneNum: this.cancelForm.phoneNum,
									areaCode: this.cancelForm.areaCode
								}
							}).then((res) => {
								if (res.code == 0) {
									this.$message.success('注销成功');
									this.handleDestroyClose();
									util.delCookie('token');
									this.$router.push('/login');
								}
							});
						})
						.catch(() => {});
				}
			});
		},
		destroySend() {
			if (!this.destroyTimer) {
				this.destroyCount = TIME_COUNT;
				this.destroyShow = false;
				this.destroyTimer = setInterval(() => {
					if (this.destroyCount > 0 && this.destroyCount <= TIME_COUNT) {
						this.destroyCount--;
					} else {
						this.destroyShow = true;
						clearInterval(this.destroyTimer); // 清除定时器
						this.destroyTimer = null;
					}
				}, 1000);
			}
			this.getCheckCode('5', this.cancelForm.phoneNum, this.cancelForm.areaCode);
		},
		getUserInfo() {
			api.getUserInfo({
				request: {}
			}).then((res) => {
				this.userInfo = res;
				this.setForm.name = this.userInfo.nickName;
				this.setForm.emial = this.userInfo.mailbox;
				this.setForm.phone = this.userInfo.phoneNumber;
				this.setForm.areaCode = this.userInfo.areaCode;
				this.cancelForm.phoneNum = this.userInfo.phoneNumber;
				this.cancelForm.areaCode = this.userInfo.areaCode;
				this.$store.commit('app/SET_STAFFID', res.staffId);
				sessionStorage.setItem('userInfo', JSON.stringify(res));
				this.$store.commit('app/SET_USERTYPE', res.userType);
				sessionStorage.setItem('comId', res.comId);
				sessionStorage.setItem('comName', res.comName);
				this.comId = res.comId;
			});
			// this.userInfo = JSON.parse(sessionStorage.getItem('users'));
			// console.log(this.userInfo);
			// this.setForm.name = this.userInfo.nickName;
			// this.setForm.emial = this.userInfo.mailbox;
			// this.setForm.phone = this.userInfo.phoneNumber;
			// this.setForm.areaCode = this.userInfo.areaCode;
			// this.cancelForm.phoneNum = this.userInfo.phoneNumber;
			// this.cancelForm.areaCode = this.userInfo.areaCode;
			// api.getUserInfo({
			//   request: {}
			// }).then((res) => {
			//   this.userInfo = res;
			//   this.setForm.name = res.nickName;
			//   this.setForm.emial = res.mailbox;
			//   this.setForm.phone = res.phoneNumber;
			//   this.setForm.areaCode = res.areaCode;
			//   this.cancelForm.phoneNum = res.phoneNumber;
			//   this.cancelForm.areaCode = res.areaCode;
			//   // localStorage.setItem("comId", res.comId);
			//   sessionStorage.setItem('comName', res.comName);
			// })
		},
		getCheckCode(type, phone, code) {
			api.getSms({
				request: {
					type,
					phoneNum: phone,
					areaCode: code
				}
			});
		},
		send() {
			if (!this.timer) {
				this.count = TIME_COUNT;
				this.show = false;
				this.timer = setInterval(() => {
					if (this.count > 0 && this.count <= TIME_COUNT) {
						this.count--;
					} else {
						this.show = true;
						clearInterval(this.timer); // 清除定时器
						this.timer = null;
					}
				}, 1000);
			}
			this.getCheckCode('3', this.changePhoneForm.oldPhoneNumber, this.changePhoneForm.oldAreaCode);
		},
		newPhoneSend() {
			if (!this.newTimer) {
				this.newCount = TIME_COUNT;
				this.newShow = false;
				this.newTimer = setInterval(() => {
					if (this.newCount > 0 && this.newCount <= TIME_COUNT) {
						this.newCount--;
					} else {
						this.newShow = true;
						clearInterval(this.newTimer); // 清除定时器
						this.newTimer = null;
					}
				}, 1000);
			}
			this.getCheckCode('4', this.changePhoneForm.newPhoneNumber, this.changePhoneForm.newAreaCode);
		},
		handleChangeClose() {
			this.$refs.changePhoneForm.clearValidate();
			this.$refs.changePhoneForm.resetFields();
			this.phoneFormVisible = false;
		},
		changePhone() {
			this.$refs['changePhoneForm'].validate((valid) => {
				if (valid) {
					api.changePhone({
						request: this.changePhoneForm
					}).then((res) => {
						if (res.code == 0) {
							this.$message.success('修改成功');
							this.handleChangeClose();
							this.getUserInfo();
						}
					});
				}
			});
		},
		toHome() {
			this.$router.push({ path: '/' });
		}
	}
};
</script>
<style>
.pl .el-form-item__label {
	padding-left: 10px;
}
</style>

<style lang="scss" scoped>
::v-deep .el-dialog--center .el-dialog__body {
	padding-top: 10px;
}
.user-dropdown {
	top: 45px !important;
	left: 93% !important;
}
.el-dropdown-menu__item {
	padding: 0 12px;
}
.el-dropdown-menu__item--divided:before {
	width: 100%;
	margin: 0;
	background: transparent;
}
.sendBtn {
	padding: 12px;
	font-size: 12px;
	width: 87%;
	transform: translateY(-2px);
}
.inputDiv {
	width: 100%;
	height: 40px;
	padding: 0 15px;
	color: #c0c4cc;
	background-color: #f5f7fa;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
}
.navbar {
	height: 50px;
	overflow: hidden;
	position: relative;
	background: #fff;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
	.hamburger-container {
		line-height: 46px;
		height: 100%;
		float: left;
		cursor: pointer;
		transition: background 0.3s;
		-webkit-tap-highlight-color: transparent;

		&:hover {
			background: rgba(0, 0, 0, 0.025);
		}
	}

	.breadcrumb-container {
		float: left;
	}

	.right-menu {
		float: right;
		height: 100%;
		line-height: 50px;
		display: inline-flex;
		align-items: center;
		&:focus {
			outline: none;
		}

		.right-menu-item {
			display: inline-block;
			padding: 0 20px;
			height: 100%;
			font-size: 18px;
			color: #5a5e66;
			vertical-align: text-bottom;

			&.hover-effect {
				cursor: pointer;
				transition: background 0.3s;

				&:hover {
					background: rgba(0, 0, 0, 0.025);
				}
			}
		}

		.avatar-container {
			margin-right: 30px;
			display: inline-flex;
			// color: #fff;
			.avatar-wrapper {
				// margin-top: 5px;
				display: inline-flex;
				position: relative;

				.user-avatar {
					cursor: pointer;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background: #fff;
					padding: 2px;
				}

				.el-icon-caret-bottom {
					cursor: pointer;
					position: absolute;
					right: -20px;
					top: 12px;
					font-size: 16px;
				}
			}
		}
	}
}

.CodeButton {
	position: absolute;
	right: 122px;
	height: 40px;
}
</style>
