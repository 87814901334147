import { get, post } from './api';
// import { request } from "http";

//登录
export const Login = (params) => post('/v1/user/login/img', params);
//获取人岗限制
export const PlanRange = (params) => post('/v1/jobfit/plan/range', { request: params });
//获取图片验证码
export const getcaptchaimg = (params) => get('/v1/common/captcha/img', params);
// 百森后台管理系统-获取数据
export const getData = (params) => post('/v1/own/get/data', { request: params });
//登出
export const Logout = (params) => get('/v1/user/logout', params);

//修改密码
export const changePwd = (params) => post('/v1/user/password/update', params);

// 查询字典表
export const getDict = (params) => get('/v1/common/dict', params);

// 图片上传 base64
export const uploadImg = (params) => post('/v1/common/upload/json', params);
// 图片上传 文件流
export const uploadFile = (params) => post('/v1/common/upload/file', params);
//当前用户权限列表
export const userpermission = (params) => post('/v1/user/permission', params);
//用户权限列表
export const permissionData = (params) => post('/v1/user/user/permission/list', params);
//用户权限列表
export const getPermissionData = (params) => post('/v1/menu/listAllByRoleIds', params);
//员工列表
export const selectPrincipallist = (params) => post('/v1/common/selectPrincipal/list', { request: params });
// 获取职位列表
export const getPositions = (params) => post('/v1/org/queryOfficeJob/list', { request: params });
//员工端消息红点
export const getCost = (params) => post('/v1/user/count', { request: params });
