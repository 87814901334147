/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null;
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
	let date;
	if (typeof time === 'object') {
		date = time;
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time);
		}
		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000;
		}
		date = new Date(time);
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	};
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key];
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value];
		}
		if (result.length > 0 && value < 10) {
			value = '0' + value;
		}
		return value || 0;
	});
	return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
	if (('' + time).length === 10) {
		time = parseInt(time) * 1000;
	} else {
		time = +time;
	}
	const d = new Date(time);
	const now = Date.now();

	const diff = (now - d) / 1000;

	if (diff < 30) {
		return '刚刚';
	} else if (diff < 3600) {
		// less 1 hour
		return Math.ceil(diff / 60) + '分钟前';
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + '小时前';
	} else if (diff < 3600 * 24 * 2) {
		return '1天前';
	}
	if (option) {
		return parseTime(time, option);
	} else {
		return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
	}
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
	const search = url.split('?')[1];
	if (!search) {
		return {};
	}
	return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

export function minBase64(file, base64) {
	return new Promise((resolve) => {
		let [img, canvas] = [new Image(), document.createElement('canvas')];
		img.src = base64;

		img.onload = () => {
			var w = img.width;
			w > 1024 && (w = 1024);
			let [h, ctx] = [(w * img.height) / img.width, canvas.getContext('2d')];
			canvas.width = w;
			canvas.height = h;
			ctx.drawImage(img, 0, 0, w, h);
			var res = canvas.toDataURL('image/jpeg', 0.92);
			// console.log(file.name + '：' + (base64.length * 3 / 4096).toFixed(2) + "k ==>" + (res.length * 3 / 4096).toFixed(2) + "k" + "  ↓↓" + ((base64.length - res.length) * 3 / 4096).toFixed(2) + 'k');
			resolve(res);
		};
	});
}
export function makePositionLevelTxt(officeInfo) {
	if (officeInfo) {
		return `${officeInfo.familyName || ''} ${officeInfo.className || ''} ${officeInfo.subclassName || ''} ${officeInfo.officeName || ''} ${
			officeInfo.officeCode || ''
		} ${officeInfo.jobName || ''} ${officeInfo.level || ''}`;
	}
	return '';
}
