import { post } from "./api";
/**
 * 列表查询
 * @param params
 * @returns {*}
 */
export const getList = (params) => post('/v1/jobfit/model/list', params);
/**
 * 添加模型
 * @param params
 * @returns {*}
 */
export const saveModel = (params) => post('/v1/jobfit/model/add', params);
/**
 * 编辑模型
 * @param params
 * @returns {*}
 */
export const updateModel = (params) => post('/v1/jobfit/model/update', params);
/**
 * 副本模型
 * @param params
 * @returns {*}
 */
export const copyModel = (params) => post('/v1/jobfit/model/copy', params);
/**
 * 删除模型
 * @param params
 * @returns {*}
 */
export const delModel = (params) => post('/v1/jobfit/model/delete', params);
/**
 * 分类指标列表
 * @param params
 * @returns {*}
 */
export const getIndicators = (params) => post('/v1/jobfit/class/indicator/list', params);
/**
 * 获取答题
 * @param params
 * @returns {*}
 */
export const getConfig = (params) => post('/v1/jobfit/answer/config', params);
/**
 * 详情回显
 * @param params
 * @returns {*}
 */
export const getAnswerInfo = (params) => post('/v1/jobfit/answer/info', params);
/**
 * 保存和提交
 * @param params
 * @returns {*}
 */
export const save = (params) => post('/v1/jobfit/answer/save', params);
/**
 * 职等列表
 * @param params
 * @returns {*}
 */
export const getEtcList = (params) => post('/v1/jobfit/job/etc/list', params);
/**
 * 职等添加
 * @param params
 * @returns {*}
 */
export const saveEtc = (params) => post('/v1/jobfit/job/etc/save', params);
/**
 * 职等删除
 * @param params
 * @returns {*}
 */
export const etcDel = (params) => post('/v1/jobfit/job/etc/delete', params);
/**
 * 指标添加
 * @param params
 * @returns {*}
 */
export const indicatorSave = (params) => post('/v1/jobfit/model/indicator/save', params);
/**
 * 分类保存
 * @param params
 * @returns {*}
 */
export const classSave = (params) => post('/v1/jobfit/model/class/save', params);
/**
 * 分类指标删除
 * @param params
 * @returns {*}
 */
export const classIndDel = (params) => post('/v1/jobfit/class/indicator/delete', params);
/**
 * 分类指标列表
 * @param params
 * @returns {*}
 */
export const getInList = (params) => post('/v1/jobfit/class/indicator/list', params);
/**
 * 指标移动
 * @param params
 * @returns {*}
 */
export const indicatorMove = (params) => post('/v1/jobfit/class/indicator/move', params);
/**
 * 指标详情
 * @param params
 * @returns {*}
 */
export const getOptionList = (params) => post('/v1/jobfit/model/option/detail', params);
/**
 * 选项保存
 * @param params
 * @returns {*}
 */
export const optionSave = (params) => post('/v1/jobfit/model/option/add', params);
/**
 * 选编辑
 * @param params
 * @returns {*}
 */
export const optionEdit = (params) => post('/v1/jobfit/model/option/edit', params);
/**
 * 选项移动
 * @param params
 * @returns {*}
 */
export const optionMove = (params) => post('/v1/jobfit/model/option/move', params);
/**
 * 选项删除
 * @param params
 * @returns {*}
 */
export const optionDel = (params) => post('/v1/jobfit/model/option/delete', params);
/**
 * 结果列表
 * @param params
 * @returns {*}
 */
export const getResult = (params) => post('/v1/jobfit/plan/result/list', params);
/**
 * 结果详情
 * @param params
 * @returns {*}
 */
export const getResultDetail = (params) => post('/v1/jobfit/plan/result/detail', params);
/**
 * 计划列表
 * @param params
 * @returns {*}
 */
export const getPlans = (params) => post('/v1/jobfit/plan/list', params);
/**
 * 分组详情
 * @param params
 * @returns {*}
 */
export const getClassList = (params) => post('/v1/jobfit/model/class/list', params);

/**
 * 分组详情
 * @param params
 * @returns {*}
 */
export const getStaffOrgList = (params) => post('/v1/customer/staff/org/list', params);
/**
 * 计划新增
 * @param params
 * @returns {*}
 */
export const savePlan = (params) => post('/v1/jobfit/plan/add', params);
/**
 * 计划编辑
 * @param params
 * @returns {*}
 */
export const editPlan = (params) => post('/v1/jobfit/plan/edit', params);
/**
 * 计划详情
 * @param params
 * @returns {*}
 */
export const getPlanInfo = (params) => post('/v1/jobfit/plan/info', params);
/**
 * 计划启动
 * @param params
 * @returns {*}
 */
export const startPlan = (params) => post('/v1/jobfit/plan/start', params);
/**
 * 计划取消
 * @param params
 * @returns {*}
 */
export const cancelPlan = (params) => post('/v1/jobfit/plan/cancel', params);
/**
 * 生成计划评估二维码
 * @param params
 * @returns {*}
 */
export const getCode = (params) => post('/v1/jobfit/plan/generate/code', params);