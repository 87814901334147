/*
 * @Author: 卢均锐morye
 * @Date: 2020-04-18 11:47:41
 * @LastEditTime: 2020-04-18 13:53:19
 */
import Layout from "../../layout";
import { Noop } from "@/utils/eleNoop";

const TrainMangement = {
  path: "/train",
  component: Noop,
  url: ["/v1/education/training/list", "/v1/education/signUp/list"],
  name: "TrainMangement",
  redirect: "/train/index",
  meta: {
    title: "学习与发展",
    // icon: '教育培训@1x'
  },
  children: [
    {
      path: "trainlist",
      name: "TrainMangementList",
      url: "/v1/education/training/list",
      component: () => import("@/views/trainMange/trainList"),
      meta: {
        title: "教育培训列表",
      },
    },
    {
      path: "signlist",
      name: "TrainMangementSignList",
      url: "/v1/education/signUp/list",
      component: () => import("@/views/trainMange/signList"),
      meta: {
        title: "培训报名列表",
      },
    },
  ],
};
export default TrainMangement;
