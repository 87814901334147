/*
 * @Author: 卢均锐morye
 * @Date: 2020-03-04 15:01:30
 * @LastEditTime: 2020-04-28 10:47:44
 */
import {
    post
} from "./api";
/**
 * 用户-审批列表
 */
export const getApprovalList = (params) => post('/v1/approval/list', { request: params });

// 获取申请属性类型
export const getApprovalProperties = (params) => post('/v1/approval/property/get', { request: params })

// 获取申请类型
export const getApprovalType = (params) => post("/v1/approval/info/get", { request: params || {} });

// 申请审批
export const applyApproval = (params) => post("/v1/approval/user/apply", { request: params });
// 处理申请
export const operateApproval = (params) => post("/v1/approval/approval/submit", { request: params });
export const getApprovalDetails = (params) => post("/v1/approval/approval/detail", { request: params });
// 获取职级体系
export function getPositionLevelList(params) {
    return post("/v1/profession/system/list", { request: params })
}
// 待自评列表/待复评列表/待确认列表
export function getAssessmentList(params) {
    return post("/v1/jobfit/self/assessment/list", { request: params })
}


export function affirmAssessment(params) {
    return post("/v1/jobfit/evaluation/affirm", { request: params })

}
export function submitEvaluation(params) {
    return post("/v1/jobfit/evaluation/submit", { request: params })
}
export function getEvaluationForm(params) {
    return post("/v1/jobfit/evaluation/start", { request: params })
}

export function saveEvaluation(params) {
    return post("/v1/jobfit/evaluation/save", { request: params })
}
export function batchJudge(params) {
    return post("/v1/jobfit/evaluation/batch/submit", { request: params })
}
export function getApplyApproval(params) {
    return post("/v1/approval/save/detail", { request: params });
}
export function updateApplyApproval(params) {
    return post("/v1/approval/again/save", { request: params });
}
//规划审批列表
export function approvelist(params) {
    return post("/v1/manpower/plan/approve/list", { request: params });
}
//规划审批提交
export function approvesubmit(params) {
    return post("/v1/manpower/plan/approve/submit", { request: params });
}

// 申请审批 morye 2020.03.06
export const recruitAnewEntryApprovalList = (params) => post("/v1/recruit/anew/entry/approval/list", { request: params });
// 申请审批 morye 2020.03.06
export const recruitAnewEntryApproval = (params) => post("/v1/recruit/anew/entry/approval", { request: params });
// 获取用户信息 morye 2020.04.08
export const approvalGetUserInfo = (params) => post("/v1/approval/get/user/info", { request: params });
// 转让/插入下级 morye 2020.04.08
export const approvalChange = (params) => post("/v1/approval/change", { request: params });
// 消息模块-普通抄送信息 morye 2020.04.28
export const coreCommonMessageList = (params) => post("/v1/core/common/message/list", { request: params });
// 消息模块-核心人力抄送信息 morye 2020.04.28
export const coreCoreMessageList = (params) => post("/v1/core/core/message/list", { request: params });
// 消息模块-消息统计 morye 2020.04.28
export const coreMessageCount = (params) => post("/v1/core/message/count", { request: params });
// 抄送模块 全部已读
export const readAll = (params) => post("/v1/core/all/read", { request: params });