import { delCookie, getCookie, setCookie } from '../utils/util';
import { Message } from 'element-ui';
import router from '../router/router';

const axios = require('axios');

axios.interceptors.request.use(
	function (config) {
		if (config) {
			// if (config.headers) {
			//     config.headers.token = getStore('token');
			// } else {
			//     config.headers = {token: getStore('token')}
			// }
			if (document.cookie.indexOf('UID') > document.cookie.indexOf('JSESSION')) {
				let JSESSION = getCookie('JSESSION');
				delCookie('JSESSION');
				setCookie('JSESSION', JSESSION, 1);
			}
		}
		return config;
	},
	function (error) {
		return Promise.reject({
			errMsg: error || '404(TimeOut)',
			errCode: 404
		});
	}
);

axios.interceptors.response.use(
	function (response) {
		let data = response.data;
		if (response.status == 200 && data && data.code === 0) {
			if (data.data) {
				if (typeof data.data === 'object') {
					data.data._responseStatusCode = 0;
				}
			} else if (data) {
				data._responseStatusCode = 0;
			}
			return data.data || data;
		} else {
			let msg;
			if (data) {
				msg = data.message;
				let errCode = data.code;
				switch (errCode) {
					case 102:
						if (!['/v1/menu/listAllByRoleIds'].includes(response.config.url)) {
							Message({
								type: 'error',
								message: msg
							});
						}
						router.replace({
							path: '/login',
							query: {
								redirect: router.currentRoute.fullPath
							}
						});
						break;
					case 101:
					case 103:
					case 104:
						Message({
							type: 'error',
							message: msg
						});
						break;
				}
			}
			return data;
		}
	},
	function (error) {
		let code = 404;
		if (error.response) {
			// console.log(error.response);
			switch (error.response.status) {
				case 401:
				case 302:
					// 401 清除token信息并跳转到登录页面
					// store.commit(storeTypes.LOGOUT);
					router.replace({
						path: '/login',
						query: {
							redirect: router.currentRoute.fullPath
						}
					});
					break;
				case 502:
					Message({
						type: 'error',
						message: '502(?)'
					});
					break;
				case 500:
					Message({
						type: 'error',
						message: error.response.data.resMsg
					});
					break;
				case 400:
					Message({
						type: 'error',
						message: error.response.data.resMsg
					});
					break;
				default:
					Message({
						type: 'error',
						message: '请求服务失败，请检查服务器状态'
					});
					break;
			}
			code = error.response.status;
		} else if (error.request) {
			Message({
				type: 'error',
				message: 'msg.res.404'
			});
			code = 101;
		}
		return Promise.reject({
			errCode: code,
			errMsg: error.response || '404(req.error)'
		});
	}
);
axios.defaults.withCredentials = true;

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

export function post(urls, params) {
	return axios.post(urls, params).then((res) => {
		return res;
	});
	// .catch((err) => {
	//     Message({
	//         type: 'error',
	//         message: typeof (err.errMsg) == 'string' ? err.errMsg : JSON.stringify(err.errMsg)
	//     });
	//     return Promise.reject(err)
	// })
}

export function get(urls, params) {
	return axios
		.get(urls, {
			params: params
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			Message({
				type: 'error',
				message: typeof err.errMsg == 'string' ? err.errMsg : JSON.stringify(err.errMsg)
			});
			return Promise.reject(err);
		});
}
