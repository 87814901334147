import {
    post
} from "./api";
/**
 * 获取验证码
 * @param params
 * @returns {*}
 */
export const getSms = (params) => post('/v1/common/captcha/sms', params);

/**
 * 注册
 * @param params
 * @returns {*}
 */
export const register = (params) => post('/v1/user/register', params);
/**
 * 校验验证码接口（管理员注册的时候点击下一步调用）
 * @param params
 * @returns {*}
 */
export const checkCode = (params) => post('/v1/user/check/code', params);
/**
 * 修改密码
 * @param params
 * @returns {*}
 */
export const changePwd = (params) => post('/v1/user/change/pwd', params);
/**
 * 账号设置
 * @param params
 * @returns {*}
 */
export const infoUpdate = (params) => post('/v1/user/info/update', params);
/**
 * 员工登录
 * @param params
 * @returns {*}
 */
export const staffLogin = (params) => post('/v1/user/staff/login', params);
/**
 * 管理员登录
 * @param params
 * @returns {*}
 */
export const adminLogin = (params) => post('/v1/user/admin/login', params);

/**
 * 注销
 * @param params
 * @returns {*}
 */
export const destroy = (params) => post('/v1/user/destroy', params);

/**
 * 获取用户信息
 * @param params
 * @returns {*}
 */
export const getUserInfo = (params) => post('/v1/user/admin/info', params);
/**
 * 修改手机号码
 * @param params
 * @returns {*}
 */
export const changePhone = (params) => post('/v1/user/change/phone', params);
/**
 * 登录
 * @param params
 * @returns {*}
 */
export const login = (params) => post('/v1/user/admin/login', params);
/**
 * 找回密码
 * @param params
 * @returns {*}
 */
export const findPwd = (params) => post('/v1/user/forget/password', params);
//生成登录二维码接口
export const qrCodeCreate = (params) => post('/v1/app/home/qr/code/create', { request: params });
//查询获取二维码的扫描结果
export const getQrCodeResult = (params) => post('/v1/app/home/qr/code/get/result', { request: params });