<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button type="text" @click="$router.go(-1)">返回</el-button>
				<el-button id="printBtn" v-print="'#judgeResult'" size="small" type="primary" icon="el-icon-receiving">打印</el-button>
				<div class="title">{{ title }}</div>
			</div>
			<div class="viewbox" id="judgeResult">
				<div class="baseWarp">
					<div class="baseBox">
						<div class="base-info_item">
							<label>姓名：</label>
							<span
								>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.staffName }}({{
									evaluateStaffBasicInfo && evaluateStaffBasicInfo.jobNumber
								}})</span
							>
						</div>
						<div class="base-info_item">
							<label>审批状态：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.statusName }}</span>
						</div>
						<div class="base-info_item">
							<label>创建时间：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.createTime }}</span>
						</div>
					</div>
					<div class="baseBox">
						<div class="base-info_item">
							<label>公司：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.companyName }}</span>
						</div>
						<div class="base-info_item">
							<label>部门：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.orgName }}</span>
						</div>
						<div class="base-info_item">
							<label>岗位：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.jobName }}</span>
						</div>
					</div>
					<div class="baseBox">
						<div class="base-info_item">
							<label>现职级代码：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.officeCode }}</span>
						</div>
						<div class="base-info_item">
							<label>现职等：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.jobEtc }}</span>
						</div>
						<div class="base-info_item">
							<label>入职时间：</label> <span>{{ evaluateStaffBasicInfo && evaluateStaffBasicInfo.entryTime }}</span>
						</div>
					</div>
				</div>
				<el-divider></el-divider>
				<div>
					<div class="title-icon">评价结果</div>
					<div class="resultWrap">
						<div>
							<div class="rowResult">
								<div>
									<label class="left_label">计划名称</label>
									<label class="right_label">{{ evaluateResultInfo && evaluateResultInfo.scheduleName }}</label>
								</div>
							</div>
							<div class="rowResult">
								<div>
									<label class="left_label">模型名称</label>
									<label class="right_label">{{ evaluateResultInfo && evaluateResultInfo.modelName }}</label>
								</div>
								<div>
									<label class="left_label">评价得分</label>
									<label class="right_label">{{ evaluateResultInfo && evaluateResultInfo.score }}</label>
								</div>
							</div>
							<div class="rowResult">
								<div>
									<label class="left_label">评价职级代码</label>
									<label class="right_label">{{ evaluateResultInfo && evaluateResultInfo.level }}</label>
								</div>
								<div>
									<label class="left_label">评价职等</label>
									<label class="right_label">{{ evaluateResultInfo && evaluateResultInfo.officeCode }}</label>
								</div>
							</div>
							<!-- <div style="margin-top:20px;">
                <label class="left_label">评价说明</label>
                <label class="right_label">{{evaluateResultInfo && evaluateResultInfo.reason}}</label>
              </div> -->
						</div>
					</div>
				</div>
				<el-divider></el-divider>
				<div>
					<div class="title-icon">职级评估</div>
					<div class="infoBox">
						<table class="table__print result-info__table">
							<tr>
								<th width="100px">评价人</th>
								<th width="100px">得分</th>
								<th width="100px">职等</th>
								<th width="100px">职级代码</th>
								<th class="table-col_5">岗位</th>
								<th class="file-list-col">附件</th>
							</tr>
							<tr v-for="(row, i) in evaluateDetail.sumOtherEvaluationList" :key="i">
								<td>
									<div>
										{{ row.assessorName }}
									</div>
								</td>
								<td>
									<div>
										{{ row.score }}
									</div>
								</td>
								<td>
									<div>
										{{ row.jobEtc }}
									</div>
								</td>
								<td>
									<div>
										{{ row.officeCode }}
									</div>
								</td>
								<td class="table-col_5">
									<div>
										{{ row.jobName }}
									</div>
								</td>
								<td class="file-list-col">
									<div>
										<a
											v-for="(file, fileIndex) in JSON.parse(row.accessoryUrl) || []"
											:key="fileIndex"
											@click="DownFile(file)"
											class="link-text fj-icon"
											><span>{{ file.name }}</span></a
										>
									</div>
								</td>
							</tr>
							<tr v-if="!evaluateDetail.sumOtherEvaluationList || evaluateDetail.sumOtherEvaluationList.length === 0">
								<td colspan="6">暂无数据</td>
							</tr>
						</table>
						<!-- <el-table :data="evaluateDetail.sumOtherEvaluationList"
                      border
                      :header-cell-style="{background:'#eef1f6',color:'#606266',textAlign:'center'}"
                      class="result-info__table">
              <el-table-column label="评价人"
                               width="100px"
                               align="center"
                               prop="assessorName"></el-table-column>
              <el-table-column label="得分"
                               width="80px"
                               align="center"
                               prop="score"></el-table-column>
              <el-table-column label="职等"
                               width="80px"
                               align="center"
                               prop="jobEtc"></el-table-column>
              <el-table-column label="职级代码"
                               width="80px"
                               align="center"
                               prop="officeCode"></el-table-column>
              <el-table-column label="岗位"
                               width="120px"
                               align="center"
                               prop="jobName"></el-table-column>
              <el-table-column prop="fileList"
                               align="center"
                               width="300px"
                               label="附件">
                <template slot-scope="scope">
                  <div v-for="(file,fileIndex) in JSON.parse(scope.row.accessoryUrl)||[]"
                       style="width:300px;"
                       :key="fileIndex">
                    <span class="link-text"
                          style="display:inline-block;"
                          v-if="validatePic(file.url)"
                          type="text"
                          @click="showPic(file.url)"><i class="el-icon-picture"></i>{{ file.name }}</span>
                    <span v-if="!validatePic(file.url)"
                          type="text"
                          @click="preview(file.url)"
                          class="link-text"
                          style="display:inline-block;">{{ file.name }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table> -->
					</div>
				</div>
				<el-divider></el-divider>
				<div style="padding: 0 0 20px">
					<div class="title-icon">职等评估</div>
					<div class="infoBox" v-for="(classes, index) in evaluateDetail.classList" :key="index">
						<div class="infoTitle" v-if="classes.className">{{ (index + 1) | numToCN }}、{{ classes.className }}</div>
						<div v-for="(indicator, inIndex) in classes.indicatorList" class="indicator" :key="inIndex">
							<h2 v-if="!classes.className" class="infoTitle">{{ (index + 1) | numToCN }}、{{ indicator.indicatorName }}</h2>
							<h3 v-else class="infoTitle">{{ inIndex + 1 }}、{{ indicator.indicatorName }}</h3>
							<div v-for="(option, opIndex) in indicator.optionList" :key="opIndex">
								<div class="options">{{ optionIndex[opIndex] }}、{{ option.content }}</div>
							</div>
							<table class="table__print evaluation__table">
								<tr>
									<th width="100px">评价人</th>
									<th class="table-col__content">选择项</th>
									<th class="table-col__content">举证说明</th>
									<th class="file-list-col">附件</th>
								</tr>
								<tr v-for="(row, i) in indicator.otherEvaluationList" :key="i">
									<td>
										<div>
											{{ row.assessorName }}
										</div>
									</td>
									<td class="table-col__content">
										<div>
											{{ row.content }}
										</div>
									</td>
									<td class="table-col__content">
										<div>
											{{ row.reason }}
										</div>
									</td>
									<td class="file-list-col">
										<div>
											<a
												v-for="(file, fileIndex) in row.fileList"
												:key="fileIndex"
												@click="DownFile(file)"
												class="link-text fj-icon"
												><span>{{ file.name }}</span></a
											>
										</div>
									</td>
								</tr>
								<tr v-if="!indicator.otherEvaluationList || indicator.otherEvaluationList.length === 0">
									<td colspan="6">暂无数据</td>
								</tr>
							</table>
							<!-- <el-table :data="indicator.otherEvaluationList"
                        border
                        :header-cell-style="{background:'#eef1f6',color:'#606266',textAlign:'center'}"
                        class="result-info__table">
                <el-table-column prop="assessorName"
                                 label="评价人"
                                 align="center"
                                 width="100px">
                </el-table-column>
                <el-table-column prop="content"
                                 class="table-col_content"
                                 label="选择项">
                </el-table-column>
                <el-table-column prop="reason"
                                 class="table-col_content"
                                 label="举证说明">
                </el-table-column>
                <el-table-column prop="fileList"
                                 width="200px"
                                 align="center"
                                 label="附件">
                  <template slot-scope="scope">
                    <div v-for="(file,fileIndex) in scope.row.fileList"
                         :key="fileIndex">
                      <span v-if="validatePic(file.url)"
                            type="text"
                            class="link-text"
                            style="display:inline-block;"
                            @click="showPic(file.url)"><i class="el-icon-picture"></i>{{ file.name }}</span>
                      <span v-if="!validatePic(file.url)"
                            type="text"
                            class="link-text"
                            style="display:inline-block;"
                            @click="preview(file.url)">{{ file.name }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table> -->
							<el-dialog :visible.sync="dialogVisible">
								<img width="100%" :src="dialogImageUrl" alt="" />
							</el-dialog>
						</div>
					</div>
				</div>
			</div>
			<div class="result-info__footer" v-if="$route.query.type === 'match_position'">
				<el-button type="primary" @click="affirmAssessment(1)">通过</el-button>
				<el-button type="primary" @click="affirmAssessment(0)">不通过</el-button>
			</div>
		</div>
	</div>
</template>
<script>
import * as api from '@/api/peopelPostMatch';
import { affirmAssessment } from '../../../api/staffSystem';
export default {
	components: {},

	data() {
		return {
			tableData: [],
			id: '',
			title: '员工人岗匹配评价结果详情',
			evaluateStaffBasicInfo: {},
			evaluateResultInfo: {},
			evaluateDetail: {},
			optionIndex: ['A', 'B', 'C', 'D', 'E', 'F'],
			dialogVisible: false,
			dialogImageUrl: ''
		};
	},
	mounted() {
		this.$nextTick(function () {
			this.id = this.$route.query.id;
			this.getDetail();
		});
	},
	methods: {
		affirmAssessment(status) {
			this.$confirm(`你确定要${status === 1 ? '通过' : '不通过'}此评价?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				affirmAssessment({
					staffResultId: this.id,
					status
				}).then((res) => {
					if (res._responseStatusCode === 0) {
						this.$message.success('操作成功!');
						this.$router.back();
					}
				});
			});
		},
		DownFile(file) {
			const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(
				JSON.stringify({ fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl })
			)}`;

			window.open(url);
		},
		getDetail() {
			api.getResultDetail({ request: { staffResultId: this.id } }).then((res) => {
				this.evaluateStaffBasicInfo = res.evaluateStaffBasicInfo;
				this.evaluateResultInfo = res.evaluateResultInfo;
				this.evaluateDetail = res.evaluateDetail || {};
			});
		},
		showPic(url) {
			this.dialogVisible = true;
			this.dialogImageUrl = url;
		},
		validatePic(url) {
			const reg = /\.(png|jpg|gif|jpng)$/;

			return reg.test(url);
		},
		preview(url) {
			window.open(`http://view.officeapps.live.com/op/view.aspx?src=${url}`);
		},
		previewImage(url) {
			window.open(url);
		}
	}
};
</script>
<style lang="scss">
@import '../../../styles/print-table.scss';
#printBtn {
	float: right;
	line-height: 12px;
	font-size: 12px;
	height: 32px;
	color: #fff;
}
@media print {
	#judgeResult {
		background-color: #fff;
		padding: 0 !important;
		height: 100%;
	}
	#judgeResult .title-icon {
		font-size: 19px;
		line-height: 22px;
	}
	#judgeResult table {
		font-size: 14px;
		width: 100% !important;
	}
	#judgeResult table.result-info__table .file-list-col {
		width: 150px !important;
	}
	#judgeResult table.evaluation__table .file-list-col {
		width: 120px !important;
	}
	#judgeResult table.result-info__table .table-col_5 {
		width: 230px !important;
	}
	#judgeResult table.evaluation__table .table-col__content {
		width: 260px !important;
	}
	#judgeResult .title-icon:before {
		display: inline-block;
		width: 8px;
		height: 22px;
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: top;
		margin-right: 5px;
	}
	#judgeResult .rowResult {
		padding: none;
		display: flex !important;
		justify-content: space-between;
	}
	#judgeResult .rowResult div {
		display: flex !important;
		align-items: flex-start;
		flex: 1;
		margin-top: 20px;
	}
	#judgeResult .rowResult div .left_label {
		margin-right: 30px;
		color: #484848;
		text-align: right;
		width: 130px;
		vertical-align: top;
		display: inline-block;
	}
	#judgeResult .rowResult div .right_label {
		width: 50%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal !important;
		text-align: left;
		font-weight: normal;
	}
	#judgeResult .baseWarp {
		display: flex;
		padding: none;
	}
	#judgeResult .baseWarp .baseBox {
		width: calc(100%);
		height: 100px;
		line-height: 1.5em;
		padding: none;
	}
	#judgeResult .baseWarp .baseBox .base-info_item {
		display: flex;
		height: 40px;
		justify-content: flex-start;
		label {
			white-space: nowrap;
		}
		span {
			flex: 0 1 200px;
		}
	}
	#judgeResult .resultWrap {
		padding-right: 0;
		padding-left: 0;
	}
	#judgeResult {
		.rowResult div {
			display: inline-block;
			width: calc(100% / 2);
			margin-top: 20px;
		}
		.infoBox {
			padding: none;
		}
		.indicator {
			margin-bottom: 40px;
		}
		.infoTitle {
			font-weight: bold;
			margin-bottom: 20px;
		}
		.options {
			margin: 10px;
			line-height: 1.7em;
		}
		.header {
			background: red;
		}
	}
}
</style>
<style lang="scss" scoped>
.result-info__footer {
	border-top: 1px solid #eaeaea;
	padding: 5px;
	text-align: center;
	position: fixed;
	background-color: #fff;
	width: 100%;
	z-index: 1000;
	bottom: 0;
}
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}
.inputDisable {
	background: #f5f7fa;
	color: #c0c4cc;
	cursor: not-allowed;
}
.assessBox {
	.app-container {
		color: #484848;
		padding: 0 20px;
		padding-bottom: 50px;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewbox {
			margin: 0 20px;
			background: #ffffff;
			min-height: 300px;
			padding: 0 10%;
		}
	}
}
.baseWarp {
	display: flex;
	padding: 20px 30px;
	.baseBox {
		width: calc(100% / 3);
		height: 100px;
		line-height: 40px;
	}
}
.result-info__table {
	.fj-icon {
		max-width: 300px;
		span {
			display: inline-block;
			width: 80%;
			overflow: hidden;
			margin-left: 5px;
			text-overflow: ellipsis;
		}
	}
	width: 80% !important;
	.file-list-col {
		width: 300px;
	}
	th.file-list-col {
		text-align: center;
	}
	td.file-list-col {
		text-align: left;
	}
	.table-col_5 {
		width: 180px;
	}
}
.evaluation__table {
	.fj-icon {
		max-width: 300px;
		span {
			display: inline-block;
			width: 80%;
			overflow: hidden;
			margin-left: 5px;
			text-overflow: ellipsis;
		}
	}
	width: 80% !important;
	.file-list-col {
		width: 300px;
	}
	th.file-list-col {
		text-align: center;
	}
	td.file-list-col {
		text-align: left;
	}
	.table-col__content {
		width: 350px;
	}
}
.resultWrap {
	padding: 20px 30px;
}
.title-icon {
	font-size: 19px;
	line-height: 22px;
	&:before {
		display: inline-block;
		width: 8px;
		height: 22px;
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: top;
		margin-right: 5px;
	}
}
.left_label {
	margin-right: 30px;
	color: #484848;
	text-align: left;
	width: 130px;
	vertical-align: top;
	display: inline-block;
}
.right_label {
	width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: left;
	font-weight: normal;
}
.rowResult div {
	display: inline-block;
	width: calc(100% / 2);
	margin-top: 20px;
}
.infoBox {
	padding: 20px 30px;
}
.indicator {
	margin-bottom: 40px;
}
.infoTitle {
	font-weight: bold;
	margin-bottom: 20px;
}
.options {
	margin: 10px;
	line-height: 1.7em;
}
.header {
	background: red;
}
</style>
