// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  const hasKey = this.$te('route.' + title)
  var translatedTitle = title
  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    translatedTitle = this.$t('route.' + title)
  }

  return translatedTitle
}
