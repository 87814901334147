import dictJson from './assets/region.json'
import schoolJson from './assets/school.json'
import industryJson from './assets/industry.json'
import NationalJson from './assets/national.json'


window.$cityList = []
if (window.$cityList.length === 0) {
    let res = dictJson
    let newArray = []
    res.response.forEach((now, index) => {
        newArray[index] = { value: now.value, label: now.label, children: [] }
        now.children.forEach(item => {
            newArray[index].children.push({ value: item.value, label: item.label })
        })
    })
    window.$cityList = newArray
}
window.$schoolList = []
if (window.$schoolList.length === 0) {
    let res = schoolJson
    let schoolArray = []
    res.forEach((now, index) => {
        schoolArray[index] = { value: now.name, label: now.name }
    })
    window.$schoolList = schoolArray
}
window.$industryList = []
if (window.$industryList.length === 0) {
    let res = industryJson
    let industryArray = []
    res.forEach((now, index) => {
        industryArray[index] = { value: now.name, label: now.name, children: [] }
        now.subs.subObj.forEach(item => {
            industryArray[index].children.push({ value: item.subName, label: item.subName })
        })
    })
    window.$industryList = industryArray
}
window.$nationalList = []
if (window.$nationalList.length === 0) {
    let res = NationalJson
    let nationalArray = []
    res.forEach((now, index) => {
        nationalArray[index] = { value: now, label: now }
    })
    window.$nationalList = nationalArray
}