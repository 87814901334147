/**
 * 根据身份证号码推算生日年龄性别
 * @param {*} id  身份证号码
 */
const getIdInfo = (id) => {
	let iden = id;
	let val = id.length;
	let info = {
		age: '',
		sex: '',
		birthday: ''
	};
	let sex = null;
	let birth = null;
	let myDate = new Date();
	let month = myDate.getMonth() + 1;
	let day = myDate.getDate();
	let age = 0;

	if (val === 18) {
		age = myDate.getFullYear() - iden.substring(6, 10) - 1;
		sex = iden.substring(16, 17);
		birth = iden.substring(6, 10) + '-' + iden.substring(10, 12) + '-' + iden.substring(12, 14);
		if (iden.substring(10, 12) < month || (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)) age++;
	}
	if (val === 15) {
		age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
		sex = iden.substring(13, 14);
		birth = '19' + iden.substring(6, 8) + '-' + iden.substring(8, 10) + '-' + iden.substring(10, 12);
		if (iden.substring(8, 10) < month || (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)) age++;
	}

	if (sex % 2 === 0) sex = '女';
	else sex = '男';

	info.sex = sex;
	info.age = age;
	info.birthday = birth;
	return info;
	// this.baseInfo.birthplace = this.area[iden.substring(0,2)];
};
/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp, notshowtime) => {
	let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	let Y = date.getFullYear() + '-';
	let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	if (!notshowtime) {
		return Y + M + D + h + m + s;
	} else {
		return Y + M + D;
	}
};
/**
 * 存储localStorage
 */
const setStore = (name, content) => {
	if (!name) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
const getStore = (name) => {
	if (!name) return;
	return window.localStorage.getItem(name);
};

/**
 * 删除localStorage
 */
const delStore = (name) => {
	if (!name) return;
	window.localStorage.removeItem(name);
};

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
	let date = new Date();
	date.setDate(date.getDate() + day);
	document.cookie = name + '=' + value + ';expires=' + date.toUTCString();
}

/**
 * 获取cookie
 **/
function getCookie(name) {
	if (process.env.NODE_ENV == 'development') {
		return 'dev';
	}
	let reg = RegExp(name + '=([^;]+)');
	let arr = document.cookie.match(reg);
	if (arr) {
		return arr[1];
	} else {
		return '';
	}
}
/**
 * isDev
 **/
function isDev() {
	return process.env.NODE_ENV == 'development';
}
export default {
	install(Vue) {
		Vue.prototype.AddDate = function (date) {
			let timestamp = Date.parse(date) / 1000 + 86400;
			date = new Date(timestamp * 1000).Format('yyyy-MM-dd');
			return date;
		};
	}
};
/**
 * 删除cookie
 **/
function delCookie(name) {
	setCookie(name, null, -1);
}
export function minBase64(file, base64, quality) {
	return new Promise((resolve) => {
		if (((base64.length * 3) / 4096).toFixed(2) <= 100) {
			// 小于100k不作处理
			// console.log(file.name + '：' + (base64.length * 3 / 4096).toFixed(2) + "k");
			resolve(base64);
		} else {
			let [img, canvas] = [new Image(), document.createElement('canvas')];
			img.src = base64;

			img.onload = () => {
				var w = img.width;
				w > 1200 && (w = 1200);
				let [h, ctx] = [(w * img.height) / img.width, canvas.getContext('2d')];
				canvas.width = w;
				canvas.height = h;
				canvas.getContext('2d').fillStyle = '#fff';
				canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(img, 0, 0, w, h);
				var res = canvas.toDataURL('image/jpeg', quality ? quality : 0.92);
				// console.log(w, '--', h);
				// console.log(file.name + '：' + (base64.length * 3 / 4096).toFixed(2) + "k ==>" + (res.length * 3 / 4096).toFixed(2) + "k" + "  ↓↓" + ((base64.length - res.length) * 3 / 4096).toFixed(2) + 'k');
				resolve(res);
			};
		}
	});
}

function copyClip(str) {
	let oInput = document.getElementById('copyInput');
	if (!oInput) {
		oInput = document.createElement('input');
		oInput.setAttribute('id', 'copyInput');
		oInput.style = 'opacity: 0;z-index: -1;';
		document.body.appendChild(oInput);
	}
	oInput.value = str;
	oInput.select(); // 选择对象
	document.execCommand('Copy'); // 执行浏览器复制命令
}

function isImageFileName(url) {
	return /\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url);
}

function isArray(res) {
	return Array.isArray(res);
}
const setUrlParams = (str) => {
	if (!str) return '';
	let result = str;
	if (typeof str !== 'string') {
		result = JSON.stringify(result);
	}
	result = Buffer.from(window.encodeURIComponent(result)).toString('Base64');
	return result;
};
const getUrlParams = (str) => {
	if (!str) return '';
	let result = str;
	result = window.decodeURIComponent(Buffer.from(result, 'base64').toString());
	// if (typeof result === 'string') {
	// 	result = JSON.parse(result);
	// }
	return result;
};
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import Vue from 'vue';

// base64转blob
function base64ToBlob(base64Data) {
	let byteString = base64Data;
	const infoList = base64Data.split(',');
	if (infoList[0].indexOf('base64') >= 0) {
		byteString = window.atob(infoList[1]); // base64 解码
	} else {
		byteString = window.decodeURIComponent(infoList[1]);
	}
	const mimeString = base64Data.split(';')[0].split(':')[1]; // mime类型

	const uintArr = new Uint8Array(byteString.length); // 创建视图

	for (let i = 0; i < byteString.length; i += 1) {
		uintArr[i] = byteString.charCodeAt(i);
	}
	// 生成blob
	const blob = new Blob([uintArr], {
		type: mimeString
	});
	return blob;
}

/**
 * 打印pdf
 * @param {*} className  dom元素className
 * @param {*} name  输出pdf文件名
 * @param {*} isDownload  是否下载
 * @param {*} isPrint 是否打印
 * @param {*} callback  回调
 */
function printDiv(className, name, isDownload = false, isPrint = false, callback) {
	const element = document.querySelector('.' + className);
	// const w = element.offsetWidth;
	// const h = element.offsetHeight;
	htmlToImage
		.toCanvas(element, {
			/*   canvasWidth: 2 * w, canvasHeight: 2 * h  */
		})
		.then((cs) => {
			const contentWidth = cs.width;
			const contentHeight = cs.height;
			const pageHeight = (contentWidth / 592.28) * 841.89;
			let leftHeight = contentHeight;
			let position = 0;
			const imgWidth = 595.28;
			const imgHeight = (592.28 / contentWidth) * contentHeight;
			const pageData = cs.toDataURL('image/jpeg', 1.0);

			const pdf = new jsPDF('', 'pt', 'a4');
			if (leftHeight < pageHeight) {
				pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
			} else {
				while (leftHeight > 0) {
					pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
					leftHeight -= pageHeight;
					position -= 841.89;
					if (leftHeight > 0) {
						pdf.addPage();
					}
				}
			}
			if (isDownload) {
				pdf.save(`${name}.pdf`);
			}
			if (isPrint) {
				const link = window.URL.createObjectURL(base64ToBlob(pdf.output('datauristring')));
				const myWindow = window.open(link);
				myWindow.print();
			}
			callback && callback(pdf);
		});
}
/**
 * 下载图片
 * @param {*} id  dom元素id
 * @param {*} name  输出图片名
 * @param {*} isDownload  是否下载
 * @param {*} callback  回调
 */
const html2Image = (id, name, call, quality = 1) => {
	const el = document.querySelector('#' + id);
	const w = el.offsetWidth;
	const h = el.offsetHeight;
	htmlToImage
		.toCanvas(el,{ canvasWidth: quality * w, canvasHeight: quality * h })
		.then((cs) => {
			downloadByCreateA(cs.toDataURL('image/png'), name);
			call && call();
		})
		.catch((error) => {
			call && call();
			console.error('html2Image wrong!', error);
		});
};
// 通过a标签下载
const downloadByCreateA = (url, name) => {
	const link = document.createElement('a');

	link.style.display = 'none';
	link.href = url;
	link.download = name || ''; // 下载后文件名
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
/*
 * tree铺平
 * child 子节点key
 */
const transfromTreeData = (cols, child = 'children') => {
	if (cols && cols.length === 0) {
		return [];
	}
	let arr = [];
	cols.forEach((item) => {
		const childs = item[child];
		const p = childs?.length ? [item, ...transfromTreeData(childs, child)] : item;
		arr = arr.concat(p);
	});
	return arr;
};
const transfromToTree = (list, childKey = 'children', isChildren, allList = []) => {
	if (!list) {
		return [];
	}
	var arr = [];
	list.forEach((data) => {
		if (data.parentId === '0') {
			let childs = list.filter(({ parentId }) => parentId === data.id);
			if (childs?.length) {
				childs = childs.sort((a, b) => a.orderNum - b.orderNum);
				data[childKey] = transfromToTree(childs, childKey, true, list);
			}
			arr.push(data);
		}
		if (isChildren) {
			let childs = allList.filter(({ parentId }) => parentId === data.id);
			if (childs?.length) {
				childs = childs.sort((a, b) => a.orderNum - b.orderNum);
				data[childKey] = transfromToTree(childs, childKey, true, allList);
			}
			arr.push(data);
		}
	});
	return arr;
};
const transfromData = (list, config = {}, num = 0) => {
	const { children, data, render, renderBy, tier = 0 } = config;
	if (!list?.length) {
		return [];
	}
	const arr = [];
	if (num >= tier && tier) {
		return;
	}
	num++;
	if (list && list.length) {
		list.forEach((item) => {
			let [childKey1, childKey2] = ['children', 'children'];
			if (children && children?.length) {
				[childKey1, childKey2] = [children[0], children[1]];
			}
			let childs = item[childKey1];
			if (item[childKey1]?.length) {
				childs = transfromData(childs, { children, data, render, renderBy, tier }, num);
			}
			const params = { ...item, [childKey2]: childs };
			if (data && data.length) {
				params[data[1]] = params[data[0]];
			}
			// Vue.delete(params[from.children]);
			if (render) {
				if (renderBy) {
					if (renderBy.includes(params.key)) {
						params.render = render;
					}
					if (!renderBy) {
						params.render = render;
					}
				}
			}

			arr.push(params);
		});
	}
	return arr;
};
// 金额格式转换
const amountFormat = (str, num = 2) => {
	if (typeof str !== 'number' && str) {
		str = Number(str);
	} else if (!str) {
		str = 0;
	}
	return str.toLocaleString('en-US', { maximumFractionDigits: num, minimumFractionDigits: num });
};
// 前端水印

const waterMark = ({
	container = document.body,
	width = '150px',
	height = '100px',
	textAlign = 'center',
	textBaseline = 'top',
	font = '14px Microsoft Yahei',
	fillStyle = 'rgba(0,0,0,0.1)',
	text = '水印',
	rotate = '-45',
	zIndex = 3000
} = {}) => {
	const args = arguments[0];
	const canvas = document.createElement('canvas');
	canvas.setAttribute('width', width);
	canvas.setAttribute('height', height);
	const ctx = canvas.getContext('2d');
	ctx.textAlign = textAlign;
	ctx.textBaseline = textBaseline;
	ctx.font = font;
	(ctx.fillStyle = fillStyle), ctx.rotate((Math.PI / 180) * rotate);
	ctx.fillText(text, parseFloat(width) * 0.2, parseFloat(height) * 0.9);
	const base64Url = canvas.toDataURL();
	const waterEl = document.querySelector('.waterEl');
	const watermarkDiv = waterEl || document.createElement('div');
	const styleStr = `
		position:absolute;
		width:100%;
		height:100%;
		z-index:${zIndex};
		pointer-events:none;
		background-repeat:repeat;
		background-image:url('${base64Url}')
	 `;
	watermarkDiv.setAttribute('style', styleStr);
	watermarkDiv.classList.add('waterEl');
	container.style.position = 'relative';
	container.insertBefore(watermarkDiv, container.firstChild);
	const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
	if (MutationObserver) {
		let mo = new MutationObserver(() => {
			const waterEl = document.querySelector('.waterEl');
			if ((waterEl && waterEl.getAttribute('style') !== styleStr) || !waterEl) {
				mo.disconnect();
				mo = null;
				waterMark(JSON.parse(JSON.stringify(args)));
			}
		});
		mo.observe(container, {
			attributes: true,
			subtree: true,
			childList: true
		});
	}
};

const nextMonthDay = (year, month) => {
	//每月多少天

	var day31 = [1, 3, 5, 7, 8, 10, 12];

	var day30 = [4, 6, 9, 11];

	if (day31.indexOf(month) > -1) {
		return 31;
	} else if (day30.indexOf(month) > -1) {
		return 30;
	} else {
		if (isLeapYear(year)) {
			return 29;
		} else {
			return 28;
		}
	}
};

const nextMonthLastDay = () => {
	//日期显示为次月最后一天

	var time = new Date();

	var year = time.getFullYear();

	//var year = 1900; //用于测试

	var month = time.getMonth() + 2;

	//var month = 0 + 2; //用于测试

	if (month > 12) {
		month = month - 12;

		year = year + 1;
	}

	var day = nextMonthDay(year, month);

	return year + '-' + month + '-' + day;
};

const isLeapYear = (year) => {
	//是否为闰年

	return year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
};
// 校验多个表单rules
const checkFormList = (list) => {
	if (!list?.length) {
		return Promise.reject();
	}
	return Promise.all(
		list.map(({ form, items }) => {
			return new Promise((resolve, reject) => {
				form.validate((valid, err) => {
					if (valid) {
						resolve(valid);
					} else {
						reject({err,items});
					}
				});
			});
		})
	);
};
const unfoldListCol = (cols) => {
	return cols
		.map((item) => item.children)
		.reduce((a = [], b = []) => {
			if (b?.length === 0) {
				b = [''];
			}
			return [...a, ...b];
		});
};
// json转化excel 下载
// export const json2excel = (data, fileName, callback, styleSet) => {
// 	setTimeout(() => {
// 		const XLSX = require('../../public/static/xlsx/xlsx');
// 		const XLSXS = require('../../public/static/xlsx/xlsx-style');
// 		let merges = [];
// 		// xlsx 头部
// 		let headerDataArr = [];
// 		const headerData = data[0];
// 		let header = [];
// 		if (Array.isArray(headerData)) {
// 			// 多层头部
// 			const arr = unfoldListCol(headerData);
// 			const obj = {};
// 			header = arr.map(({ key }) => key);
// 			arr.forEach(({ label, key }) => {
// 				obj[key] = label;
// 			});
// 			// 层级 需要合并单元格
// 			let row = {};
// 			let start = 0;
// 			headerData.forEach((item, index) => {
// 				const leng = item.children.length - 1;
// 				if (item.children.length) {
// 					row[item.children[0].key] = item.label || item.children[0]?.label;
// 				}
// 				if (leng >= 1) {
// 					merges.push({ s: { r: 1, c: start }, e: { r: 1, c: leng + start } });
// 				}
// 				start += leng + 1;
// 			});
// 			headerDataArr.push(row);
// 			headerDataArr = [...headerDataArr, obj]; // 最后一层
// 		} else {
// 			// 只有一层 情况
// 			Object.keys(data[0]).forEach((name) => {
// 				header.push(name);
// 			});
// 			headerDataArr = [data[0]];
// 		}
// 		const tdata = data.slice(1).reduce((result, val) => {
// 			const temp = {};

// 			header.map((key) => {
// 				temp[key] = val[key] || '';
// 			});
// 			result.push(temp);
// 			return result;
// 		}, []);
// 		const ws = XLSX.utils.json_to_sheet([...headerDataArr, ...tdata], { header });
// 		// 单元格配置
// 		ws['!rows'] = ws['!rows'] || [];
// 		ws['!rows'][0] = ws['!rows'][0] || {};
// 		//  隐藏表头key字段
// 		ws['!rows'][0].hidden = true;
// 		ws['!rows'][0].hpx = 40;

// 		ws['!cols'] = ws['!cols'] || [];

// 		header.map((item, index) => {
// 			ws['!cols'][index] = ws['!cols'][index] || {};
// 			ws['!cols'][index].wpx = (styleSet && styleSet.width) || 160;
// 			// 设置样式
// 			if (styleSet) {
// 				const list = [...headerDataArr, ...tdata];
// 				let cell_address = { c: index, r: 1 };
// 				const headerSets = styleSet.header;
// 				const colSets = styleSet.col;
// 				const rowSet = styleSet.row;
// 				if (rowSet) {
// 					// 行
// 					list.forEach((rowData, rowInd) => {
// 						rowSet.forEach(({ target, style }) => {
// 							if (target.includes(rowInd)) {
// 								let r_address = { c: index, r: rowInd + 1 };
// 								let r_ref = XLSXS.utils.encode_cell(r_address);
// 								if (ws[r_ref]) {
// 									ws[r_ref] = Object.assign(ws[r_ref], style);
// 								}
// 								if (style?.hidden) {
// 									ws['!rows'][rowInd] = { hidden: true };
// 								}
// 							}
// 							if (target === 'all') {
// 								let r_address = { c: index, r: rowInd + 1 };
// 								let r_ref = XLSXS.utils.encode_cell(r_address);
// 								let newCell = ws[r_ref];
// 								if (style?.s) {
// 									newCell = {
// 										...newCell,
// 										s: { ...newCell.s, ...style.s }
// 									};
// 								}
// 								ws[r_ref] = newCell;
// 							}
// 						});
// 					});
// 				}
// 				if (colSets) {
// 					// 列
// 					list.forEach((rowData, rowInd) => {
// 						let c_address = { c: index, r: rowInd + 1 };
// 						colSets.forEach((it) => {
// 							if (rowInd < headerDataArr.length) return;
// 							if (it.target.includes(item)) {
// 								let cell_ref = XLSXS.utils.encode_cell(c_address);
// 								ws[cell_ref] = Object.assign(ws[cell_ref], it.style);
// 								if (it.style?.hidden) {
// 									ws['!cols'][index].wpx = 0.1;
// 								}
// 								if (it.merges) {
// 									ws['!merges'] = ws['!merges'] || [];
// 									ws['!merges'] = [...ws['!merges'], it.merges];
// 								}
// 							}
// 						});
// 					});
// 				}
// 				if (headerSets) {
// 					//头部
// 					headerSets.forEach((it) => {
// 						if (it.target.includes(item)) {
// 							let cell_ref = XLSXS.utils.encode_cell(cell_address);
// 							let newCell = ws[cell_ref];
// 							if (it.style?.s) {
// 								newCell = {
// 									...newCell,
// 									s: { ...(newCell?.s || {}), ...(it.style?.s || {}) }
// 								};
// 							}
// 							if (Array.isArray(headerData)) {
// 								let cell_ref1 = XLSXS.utils.encode_cell({ ...cell_address, r: 2 });
// 								ws[cell_ref1] = { ...ws[cell_ref1], s: { ...(ws[cell_ref1]?.s || {}), ...(it.style?.s || {}) } };
// 							} else {
// 								ws[cell_ref] = newCell;
// 							}
// 						}
// 					});
// 				}
// 			}
// 		});
// 		// 设置边框
// 		const borderStyle = {
// 			top: {
// 				style: 'thin',
// 				color: { rgb: '000000' }
// 			},
// 			bottom: {
// 				style: 'thin',
// 				color: { rgb: '000000' }
// 			},
// 			left: {
// 				style: 'thin',
// 				color: { rgb: '000000' }
// 			},
// 			right: {
// 				style: 'thin',
// 				color: { rgb: '000000' }
// 			}
// 		};
// 		for (let key in ws) {
// 			if (typeof ws[key] === 'object') {
// 				if (/2|3/g.test(key)) {
// 					ws[key].s = {
// 						...(ws[key].s || {}),
// 						border: borderStyle,
// 						alignment: {
// 							horizontal: 'center', //水平居中对齐
// 							vertical: 'center'
// 						},
// 						bold: false,
// 						numFmt: 0
// 					};
// 				}
// 			}
// 		}
// 		if (merges.length) {
// 			ws['!merges'] = [...(ws['!merges'] || []), ...merges];
// 			ws['!merges'].forEach((item) => {
// 				const en_cell = XLSX.utils.encode_cell(item.s);
// 				ws[en_cell].s = {
// 					...(ws[en_cell].s || {}),
// 					alignment: {
// 						horizontal: 'center',
// 						vertical: 'center'
// 					}
// 				};
// 			});
// 		}

// 		const wb = {
// 			Sheets: { Sheet: ws },
// 			SheetNames: ['Sheet']
// 		};
// 		const wbout = XLSXS.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

// 		const fileBuff = new ArrayBuffer(wbout.length);
// 		const view = new Uint8Array(fileBuff);

// 		for (let i = 0; i !== wbout.length; ++i) {
// 			// eslint-disable-next-line no-bitwise
// 			view[i] = wbout.charCodeAt(i) & 0xff;
// 		}

// 		const file = new Blob([fileBuff], { type: 'application/octet-stream' });
// 		const url = (window.URL || window.webkitURL).createObjectURL(file);

// 		const link = document.createElement('a');

// 		link.href = url;
// 		link.style.display = 'none';
// 		link.download = `${fileName}.xlsx`;

// 		document.body.appendChild(link);
// 		link.click();
// 		document.body.removeChild(link);
// 		callback && callback();
// 	}, 10);
// };
const Work = {
	run: (data) => {
		return new Promise((resolve) => {
			const worker = new Worker('/static/js/work.js');
			worker.onmessage = function (e) {
				const d = JSON.parse(e.data);
				resolve(d);
				worker.terminate();
			};
			worker.postMessage(JSON.stringify(data));
		});
	}
}
/**
 * 导出
 **/
export {
	Work,
	html2Image,
	checkFormList,
	transfromToTree,
	nextMonthLastDay,
	waterMark,
	amountFormat,
	transfromData,
	transfromTreeData,
	downloadByCreateA,
	printDiv,
	isDev,
	copyClip,
	isImageFileName,
	timestampToTime,
	setStore,
	getStore,
	delStore,
	setCookie,
	getCookie,
	delCookie,
	isArray,
	getIdInfo,
	setUrlParams,
	getUrlParams
};
