<template>
	<!-- <div class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
    <sidebar class="sidebar-container"/>
    <div class="main-container">
      <div :class="{'fixed-header':true}">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar/>
      </div>
      <div style="margin-top:50px">
        <breadcrumb class="breadcrumb-container"/>
        <app-main/>
      </div>
    </div>
  </div>-->
	<div :class="classObj" class="app-wrapper">
		<Navbar showType="1" class="homePageHeader" />
		<div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
		<sidebar />
		<div class="main-container">
			<div :class="{ 'fixed-header': fixedHeader }">
				<!-- <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" /> -->
				<!-- <div class="hamburgerBox" @click="toggleSideBar">
					<div class="iconWrap">
						<i :class="`${sidebar.opened ? 'el-icon-caret-left' : 'el-icon-caret-right'}`" style="color: #0000006e"></i>
					</div>
				</div> -->
				<!-- <navbar /> -->
			</div>
			<div>
				<!-- <breadcrumb class="breadcrumb-container"/> -->
				<!-- <div class="rightbox">
          <el-button type="text">导入</el-button>
          <el-button type="text">设置</el-button>
        </div> -->
				<app-main />
			</div>
		</div>
	</div>
</template>

<script>
import { Sidebar, Navbar, AppMain } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import { throttle } from 'loadsh';
// import Hamburger from './components/Navbar/Hamburger.vue';
export default {
	name: 'Layout',
	components: {
		Sidebar,
		Navbar,
		AppMain
		// Hamburger
		// Breadcrumb
	},
	mixins: [ResizeMixin],
	computed: {
		sidebar() {
			return this.$store.state.app.sidebar;
		},
		device() {
			return this.$store.state.app.device;
		},
		fixedHeader() {
			return this.$store.state.settings.fixedHeader;
		},
		classObj() {
			return {
				hideSidebar: !this.sidebar.opened,
				openSidebar: this.sidebar.opened,
				withoutAnimation: this.sidebar.withoutAnimation,
				mobile: this.device === 'mobile'
			};
		}
	},
	mounted() {
		this.scrollThrottle = throttle(this.scrollView, 200);
		document.addEventListener('scroll', this.scrollThrottle);
	},
	methods: {
		scrollView() {
			let [bodyScrollTop, docScrollTop, scrollTop] = [0, 0, 0];

			if (document.body) {
				bodyScrollTop = document.body.scrollTop;
			}
			if (document.documentElement) {
				docScrollTop = document.documentElement.scrollTop;
			}
			scrollTop = bodyScrollTop - docScrollTop > 0 ? bodyScrollTop : docScrollTop;
			const el = document.querySelector('.sidebar-container');

			if (el) {
				if (scrollTop > 68) {
					el.style.position = 'fixed';
					el.style.top = '0px';
				} else if (scrollTop < 68) {
					el.style.position = 'absolute';
					el.style.top = '68px';
				}
			}
		},
		handleClickOutside() {
			this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
		},
		toggleSideBar() {
			this.$store.dispatch('app/toggleSideBar');
		}
	},
	beforeDestroy() {
		document.removeEventListener('scroll', this.scrollThrottle);
	}
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
@import '@/styles/variables.scss';

.app-wrapper {
	@include clearfix;
	position: relative;
	height: 100%;
	width: 100%;
	.hamburger-container {
		position: absolute;
		padding: 4px !important;
		top: -30px;
		cursor: pointer;
	}
	.hamburgerBox {
		height: 60px;
		width: 8px;
		border-radius: 0px 8px 8px 0px;
		position: fixed;
		top: calc(50vh - 200px);
		cursor: pointer;
		background: #0000003d;
		transition: 0.3s 0.1s;
		.iconWrap {
			height: 100%;
			width: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}
	.hamburgerBox:hover {
		width: 16px;
		border-radius: 8px;
	}
	&.mobile.openSidebar {
		position: fixed;
		top: 0;
	}
	.homePageHeader {
		&.navbar {
			display: inline-flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			height: 68px;
			z-index: 1;
			// background: #4a8df0;
			// color: #fff;
			// :deep(.right-menu) {
			// 	line-height: 68px !important;
			// }
		}
	}
}
.drawer-bg {
	background: #000;
	opacity: 0.3;
	width: 100%;
	top: 0;
	height: 100%;
	position: absolute;
	z-index: 999;
}

.fixed-header {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1000;
	width: calc(100% - #{$sideBarWidth});
	transition: width 0.28s;
}

.hideSidebar .fixed-header {
	width: calc(100% - 54px);
}
.rightbox {
	float: right;
	margin: 10px 30px;
}
.mobile .fixed-header {
	width: 100%;
}
</style>
