import { EVALUATIONPROBLEM } from '../types'
const state = {
    problems: null,

}
const mutations = {
    [EVALUATIONPROBLEM] (state, action) {
        state.problems = action;
    },

}
export default {
    state,
    mutations
}