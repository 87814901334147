const getters = {
  language: state => state.app.language,
  sidebar: state => state.app.sidebar,
  dict: state => {
    return state.app.dict.filter(item => { // 根据语言过滤字典表
      if (item.languageCode === state.app.language) {
        return item
      }
    })
  }
}
export default getters