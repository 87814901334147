<template>
	<svg :class="svgClass" aria-hidden="true" v-on="$listeners">
		<use :xlink:href="iconName" />
	</svg>
	<!-- <svg-icon class-name="international-icon" icon-class="language" /> -->
</template>

<script>
export default {
	name: 'SvgIcon',
	props: {
		iconClass: {
			type: String
		},
		className: {
			type: String,
			default: ''
		}
	},
	computed: {
		iconName() {
			return `#icon-${this.iconClass}`;
		},
		svgClass() {
			if (this.className) {
				return `svg-icon ${this.className}`;
			}
			return 'svg-icon';
		}
	}
};
</script>

<style scoped>
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
