import Layout from '@/layout';
import { Noop } from '@/utils/eleNoop';
const corehuman = {
	path: '/corehuman',
	component: Noop,
	name: 'Corehuman',
	meta: {
		title: 'Corehuman'
		// icon: '核心人力@1x'
	},
	children: [
		{
			path: 'coreaudit',
			// redirect: '/coreaudit/list',
			name: 'CoreManpowerApproval',
			component: () => import('@/views/corehuman/index'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'CoreHumanAudit'
			},
			children: [
				{
					path: '/',
					name: 'CoreAudit',
					hidden: true,
					component: () => import('@/views/corehuman/list'),
					// import ('@/views/developping/index'),
					meta: {
						title: '列表'
					}
				}
			]
		},
		{
			path: 'corereview',
			// redirect: '/corereview/list',
			name: 'CoreManpowerReview',
			component: () => import('@/views/corehuman/index'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'CoreHumanReview'
			},
			children: [
				{
					path: '/',
					name: 'CoreReview',
					hidden: true,
					component: () => import('@/views/corehuman/list'),
					// import ('@/views/developping/index'),
					meta: {
						title: '列表'
					}
				}
			]
		},
		{
			path: 'statement',
			// redirect: '/statement/list',
			name: 'CoreHrReport',
			component: () => import('@/views/corehuman/index'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'corereviewStatement'
			},
			children: [
				{
					path: '/',
					name: 'Statement',
					hidden: true,
					component: () => import('@/views/corehuman/list'),
					// import ('@/views/developping/index'),
					meta: {
						title: '列表'
					}
				}
			]
		},
		{
			path: 'levelConfig',
			// redirect: '/levelConfig/list',
			name: 'levelConfig',
			component: () => import('@/views/corehuman/index'),
			// import ('@/views/developping/index'),
			meta: {
				title: 'levelConfig'
			},
			children: [
				{
					path: '/',
					name: 'levelConfig',
					hidden: true,
					component: () => import('@/views/corehuman/config'),
					// import ('@/views/developping/index'),
					meta: {
						title: '列表'
					}
				}
			]
		}
	]
};
export default corehuman;
