const WapLogin = () =>
    import ( /* webpackChunckName: "wap_login" */ '../../views/mobile/login.vue');
const Judge = () =>
    import ( /* webpackChunckName: "wap_judge" */ '../../views/mobile/judge.vue');
const Evaluations = () =>
    import ( /* webpackChunckName: "wap_evaluations" */ '../../views/mobile/evaluations.vue');
const Registrationform = () =>
    import ( /* webpackChunckName: "wap_evaluations" */ '../../views/recruitment_allocation/interviewmanagement/table');

export default {
    path: '/wap',
    hidden: true,
    component: {
        render(c) {
            return c('router-view');
        }
    },
    redirect: "/wap/login",
    children: [{
        path: 'login/:type',
        component: WapLogin
    }, {
        path: "judge/:type",
        component: Judge
    }, {
        path: "evaluations",
        component: Evaluations
    }, {
        path: 'registrationform',
        component: Registrationform
    }]
}