<template>
	<div>
		<!-- <logo :collapse="false"/> -->
		<!-- <logo v-if="showLogo" :collapse="isCollapse" /> -->
		<el-scrollbar wrap-class="scrollbar-wrapper" class="sidebar-container">
			<el-menu
				:default-active="activeMenu"
				:background-color="variables.menuBg"
				:text-color="variables.menuText"
				:active-text-color="variables.menuActiveText"
				:collapse="isCollapse"
				:unique-opened="false"
				:collapse-transition="false"
			>
				<sidebar-item v-for="route in menus" :key="route.path + route.menuName" :item="route" :base-path="route.path" />
			</el-menu>
			<div style="height: 40px; color: #fff; font-size: 14px; padding: 0 20px; line-height: 40px; margin-top: 10px">
				<div style="cursor: pointer" @click="toggleSideBar">
					<i
						:class="`${sidebar.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold'}`"
						style="margin-right: 10px"
						:title="`${sidebar.opened ? '' : '展开菜单'}`"
					></i
					>{{ sidebar.opened ? '收起菜单' : '' }}
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Logo from './Logo';
import SidebarItem from './SidebarItem';
import variables from '@/styles/variables.scss';

export default {
	components: {
		// Logo,
		SidebarItem
	},
	data() {
		return {
			menusList: []
		};
	},
	watch: {
		'$store.state.app': {
			deep: true,
			handler(val) {
				if (val.menus?.length) {
					this.menusList = val.menus;
				}
			}
		}
	},
	computed: {
		...mapGetters(['sidebar']),
		routes() {
			return this.$router.options.routes;
		},
		menuItemTopId() {
			return this.$store.state.app.menuItemTopId;
		},
		menus() {
			if (this.menuItemTopId) {
				return this.$store.state.app.menus.filter(({ topId }) => topId === this.menuItemTopId);
			}
			if (this.menusList?.length) {
				return this.menusList;
			}
			return this.$store.state.app.menus;
		},
		activeMenu() {
			const route = this.$route;

			// eslint-disable-next-line prefer-const
			let { meta, path } = route;
			// if set path, the sidebar will highlight the path you set

			if (meta.activeMenu) {
				return meta.activeMenu;
			}
			// 修正隐藏sidebar自动下拉问题
			const hashidePath = path.match(/(\/.+)+(\/add|\/list|\/info|\/edit)/);

			if (path === '/approval/index') {
				return '/approval';
			}
			if (path === '/companys/index') {
				return '/companys';
			}
			// console.log(path)
			path = hashidePath ? hashidePath[1] : path;
			// console.log(path)
			return path;
		},
		variables() {
			return variables;
		},
		isCollapse() {
			return !this.sidebar.opened;
		},
		showLogo() {
			return this.$store.state.settings.sidebarLogo;
		},
		sidebar() {
			return this.$store.state.app.sidebar;
		}
	},
	mounted() {},
	// watch: {
	//   "$store.state.app.permission"() {
	//     let permissionlist = [];
	//     this.$store.state.app.permission.length > 0 &&
	//       this.$store.state.app.permission.map(item => {
	//         permissionlist.push(item.url);
	//       });
	//     this.routes.map(item => {
	//       let length = 0;
	//       if (item.children) {
	//         item.children.filter(it => {
	//           if (it.url && permissionlist.indexOf(it.url) === -1) {
	//             it.hidden = true;
	//             length++;
	//           }
	//           if (it.children) {
	//             let childrenlength = 0;
	//             it.children.forEach(element => {
	//               if (element.url && permissionlist.indexOf(element.url) === -1) {
	//                 element.hidden = true;
	//                 childrenlength++;
	//               }
	//             });
	//             if (it.children.length === childrenlength) {
	//               it.hidden = true;
	//             }
	//           }
	//         });
	//         if (item.children.length === length) {
	//           item.hidden = true;
	//         }
	//       }
	//     });
	//   }
	// },
	created() {},
	methods: {
		toggleSideBar() {
			this.$store.dispatch('app/toggleSideBar');
		}
	}
};
</script>
