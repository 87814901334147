import store from '@/store';

// 获取权限列表
const getPerssion = store.dispatch('app/setMenusPermission');
/** 访问过滤 */
const filter = async (to, next) => {
	getPerssion.then((res) => {
		const permissionList = store.state.app.menusPermission || [];
		const hasPermission = Array.from(new Set(permissionList.map(({ path }) => path))).includes(to.path);
		const notCheckPages = [
			'/',
			'/register',
			'/404',
			'/workbench',
			'/login',
			'/index',
			'/staff/approvalApply',
			'/staff/changeApply',
			'/staff/orgApply',
			'/staff/orgSubmit',
			'/staff/approval_details',
			'/useTalentCorrectly/talentEvaluation/report', // ppt展示编辑
			'/projectPptReport',
			'/pptTemplateSet', // 模板配置
			'/dictionaryConfig', // 字典配置页
			'/recordDetail', // 录音详情
		];
		if (!hasPermission && !notCheckPages.includes(to.path)) {
			return next({ path: '/404' });
		}
		const isWapPage = /^\/wap\/.+$/.test(to.path);
		if (isWapPage) {
			const isWapLoginPage = /^\/wap\/login.+$/.test(to.path);
			if (to.path.includes('registrationform')) {
				next();
			} else if (isWapLoginPage) {
				sessionStorage.setItem('wap', to.params.type);
				sessionStorage.removeItem('selfJudgeWapLoginInfo');
				sessionStorage.removeItem('againJudgeWapLoginInfo');
				next();
			} else if (!isWapLoginPage && isWapPage) {
				const wap = sessionStorage.getItem('wap');
				if (wap === 'self' && !sessionStorage.getItem('selfJudgeWapLoginInfo')) {
					let query = sessionStorage.getItem('wapLoginQuery') ? sessionStorage.getItem('wapLoginQuery') : '{}';
					next({ path: '/wap/login/self', query: JSON.parse(query) });
				} else if (wap === 'again' && !sessionStorage.getItem('againJudgeWapLoginInfo')) {
					let query = sessionStorage.getItem('wapLoginQuery') ? sessionStorage.getItem('wapLoginQuery') : '{}';
					next({ path: '/wap/login/again', query: JSON.parse(query) });
				} else {
					next();
				}
			} else {
				next();
			}
		} else {
			next();
		}
	});
};

export default filter;
