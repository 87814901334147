import Layout from '@/layout';
import { Noop } from '@/utils/eleNoop';

const systemConfig = {
	path: '/system-config',
	component: Layout,
	redirect: '/system-config',
	name: 'System-config',
	meta: {
		title: 'rightsprofile',
		icon: 'shezhi'
	},
	children: [
		{
			path: 'systemManagement',
			name: 'SystemManagement',
			redirect: 'systemManagement/menuMangement',
			component: Noop,
			meta: {
				title: '平台系统管理'
			},
			children: [
				{
					path: 'menuMangement',
					name: 'MenuMangement',
					component: () => import('@/views/system-config/platformSystemConfig/menuList'),
					meta: {
						title: '菜单管理'
					}
				}
			]
		},
		{
			path: 'role',
			name: 'RoleSet',
			component: () => import('@/views/system-config/index'),
			meta: {
				title: 'role'
			}
		},
		// {
		//   path: "orgPrinList",
		//   name: "orgPrinList",
		//   url: ["/v1/user/admin/list", "/v1/user/role/list"],
		//   component: () =>
		//     import("@/views/system-config/orgPrinConfig/orgPrinList"),
		//   meta: {
		//     title: "orgPrinList",
		//   },
		// },
		{
			path: 'admin',
			name: 'RoleBind',
			component: () => import('@/views/system-config/rights-profile/admin'),
			meta: {
				title: 'admin'
			}
		}
	]
	// children: [{
	//         path: 'rightsprofile',
	//         name: 'rightsprofile',
	//         redirect: '/system-config/rightsprofile/admin',
	//         component: () =>
	//             import ('@/views/system-config/rights-profile/index'),
	//         meta: {
	//             title: 'rightsprofile',
	//         },

	//     },
	//     {
	//         path: 'quit',
	//         name: 'ConfigQuit',
	//         url: '/v1/org/quit/config/list',
	//         component: () =>
	//             import ('@/views/config/quitList'),
	//         meta: {
	//             title: '离职类型配置',
	//         },
	//     }
	// ]
};

export default systemConfig;
