import Layout from '@/layout';

const companys = {
	path: 'companys',
	name: 'Companys',
	component: Layout,
	redirect: '/companys/index',
	meta: {
		title: 'companys',
		icon: '企业文化@1x'
	},
	children: [
		{
			path: '/companys/companyInformationSet',
			name: 'companyInformationSet',
			component: () => import('@/views/companys/companyInformationSet'),
			meta: {
				title: 'companyInformationSet'
			}
		},
		{
			path: '/companys/systemUiConfig',
			name: 'systemUiConfig',
			component: () => import('@/views/companys/systemUiConfig'),
			meta: {
				title: 'systemUiConfig'
			}
		}
	]
};
export default companys;
