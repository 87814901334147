const dataList = {
	state: {
		officeList: [],
		rankList: [],
		positionList: [],
		centerList: [],
		principalList: [],
		dictList: [],
		depsList: [],
		options: {}
	},
	mutations: {
		SET_OFFICELIST(state, officeList) {
			state.officeList = officeList;
		},
		SET_RANKLIST(state, rankList) {
			state.rankList = rankList;
		},
		SET_POSITIONLIST(state, positionList) {
			state.positionList = positionList;
		},
		SET_CENTERLIST(state, centerList) {
			state.centerList = centerList;
		},
		SET_PRINCIPALLIST(state, principalList) {
			state.principalList = principalList;
		},
		SET_DISTLIST(state, dictList) {
			state.dictList = dictList;
		},
		SET_DEPT(state, depsList) {
			state.depsList = depsList;
		},
		SET_OPTIONS(state, options) {
			state.options = options;
		}
	}
};

export default dataList;
