import Layout from '@/layout';
import achievementsManagement from './achievementsManagement'; // 绩效
import trainMangement from './trainMangement'; // 学习发展
import { Noop } from '@/utils/eleNoop';

const doRightThings = {
	path: '/doRightThings',
	component: Layout,
	name: 'DoRightThings',
	meta: {
		title: '做对事',
		icon: 'index'
	},
	redirect: '/doRightThings/indicatorsOfLibrary',
	children: [
		{
			path: 'indicatorsOfLibrary',
			name: 'IndicatorsOfLibrary',
			component: Noop,
			component: () => import('@/views/doRightThings/indicatorsOfLibrary/index'),
			meta: {
				title: '指标库'
			}
		},
		{
			path: 'performanceGapAnalysis',
			name: 'PerformanceGapAnalysis',
			component: Noop,
			component: () => import('@/views/doRightThings/performanceGapAnalysis/list'),
			meta: {
				title: '差距分析'
			}
		},
		{
			path: 'strategyFormulation',
			name: 'StrategyFormulation',
			redirect: '/strategyFormulation/marketInsight',
			component: Noop,
			meta: {
				title: '战略制定'
			},
			children: [
				{
					path: 'marketInsight',
					name: 'MarketInsight',
					component: () => import('@/views/doRightThings/strategyFormulation/marketInsight/list'),
					meta: {
						title: '市场洞察'
					}
				},
				{
					path: 'strategicIntent',
					name: 'StrategicIntent',
					component: () => import('@/views/doRightThings/strategyFormulation/strategicIntent/index.vue'),
					meta: {
						title: '战略意图'
					}
				},
				{
					path: 'innovationFocus',
					name: 'InnovationFocus',
					component: () => import('@/views/doRightThings/strategyFormulation/innovationFocus/index.vue'),
					meta: {
						title: '创新焦点'
					}
				},
				{
					path: 'businessDesign',
					name: 'BusinessDesign',
					component: () => import('@/views/doRightThings/strategyFormulation/businessDesign/index.vue'),
					meta: {
						title: '业务设计'
					}
				}
			]
		},
		{
			path: 'strategicDecoding',
			name: 'StrategicDecoding',
			component: () => import('@/views/doRightThings/strategyFormulation/strategicDecoding/index.vue'),
			meta: {
				title: '战略解码'
			}
		},
		{
			path: '/organizationTargetResponsibility',
			name: 'OrganizationTargetResponsibility',
			component: Noop,
			meta: {
				title: '组织目标责任书'
			},
			children: [
				{
					path: 'list',
					hidden: true,
					component: null,
					meta: {
						title: '列表'
					}
				}
			]
		},
		{
			path: '/individualTargetResponsibility',
			name: 'IndividualTargetResponsibility',
			redirect: '/individualTargetResponsibility/list',
			component: Noop,
			meta: {
				title: '个人目标责任书'
			},
			children: [
				{
					path: 'list',
					hidden: true,
					component: null,
					meta: {
						title: '列表'
					}
				}
			]
		},

		achievementsManagement,
		trainMangement
		// {
		//   path: "/performanceManagement",
		//   name: "PerformanceManagement",
		//   redirect: "/performanceManagement/list",
		//   component: Noop,
		//   meta: {
		//     title: "绩效管理",
		//   },
		//   children: [
		//     {
		//       path: "list",
		//       hidden: true,
		//       component: null,
		//       meta: {
		//         title: "列表",
		//       },
		//     },
		//   ],
		// },
		// {
		//   path: "/learningAndDevelopment",
		//   name: "LearningAndDevelopment",
		//   redirect: "/learningAndDevelopment/list",
		//   component: Noop,
		//   meta: {
		//     title: "学习与发展",
		//   },
		//   children: [
		//     {
		//       path: "list",
		//       hidden: true,
		//       component: null,
		//       meta: {
		//         title: "列表",
		//       },
		//     },
		//   ],
		// },
	]
};
export default doRightThings;
