export default {
	route: {
		index: '首页',
		organizingpersonnel: '基础人事',
		organizing: '组织体系',
		talentassessment: '人才评估',
		assess: '人才评估',
		peoplepostmatch: '人岗匹配',
		matchModel: '模型',
		matchPlan: '招聘计划',
		matchResult: '计划审批',
		manpowerplanning: '人力规划',
		// "mpPlan":"计划",
		// "mpResult":"结果",
		audit: '评估审核',
		companys: '系统管理',
		companyInformationSet: '公司信息管理',
		systemUiConfig: '系统美化管理',
		company: '公司',
		approval: '审批管理',
		setting: '设置',
		assess_detail: '人才评估详情',
		talentassessmentcenter: '人才评估中心',
		position: '职位体系',
		humanaffairs: '人事信息',
		customer: '客户',
		recruitment_allocation: '招聘调配',
		recruitmentplan: '招聘计划',
		resumelibrary: '简历库',
		interviewmanagement: '面试管理',
		admissionmanagement: '录取管理',
		achievementsmanagement: '绩效管理',
		brainbank: '人才库',
		recruitmentstatistics: '招聘统计',
		recruitmentsettings: '招聘设置',
		recruitmentpostmanagement: '招聘岗位管理',
		rightsprofile: '权限管理',
		admin: '角色绑定',
		role: '角色设置',
		orgPrinList: '组织负责人',
		Corehuman: '核心人力',
		CoreHumanAudit: '核心人力审批',
		CoreHumanReview: '核心人力复查',
		corereviewStatement: '核心人力报表',
		levelConfig: '申报等级配置',
		attendanceManage: '考勤管理',
		oldRecord: '原始记录',
		clockTime: '打卡时间',
		attendanceAll: '考勤汇总',
		'model-library': 'Model library',
		'news-library': 'News library',
		'tag-library': 'Tag library',
		recommend: 'Recommendy',
		brand: 'Brand manange',
		model: 'Model manange',
		car: 'Car manange',
		'article-manage': 'Article manange',
		'column-manage': 'Column manage',
		'configure-manage': 'Configure manage',
		'car-tag': 'Car tag',
		'model-tag': 'Model tag',
		'news-tag': 'News tag',
		'hot-list': 'Hot list',
		'banner-list': 'Banner list',
		'country-config': 'Country config',
		'system-config': '系统配置',
		'params-config': 'Params config',
		salaryRangeSetting: '薪酬带宽',
		distributionOfWelfare: '分好钱',
		remunerationSetting: '模块设置',
		wageParameter: '工资台账',
		whenMonthlyWages: '当月工资',
		salaryHistory: '历史工资',
		analysisOfTheCompensation: '薪酬分析',
		allocationOfBonus: '分奖金',
		YearendBonusManagement: '年终奖管理',
		otherBonusManagement: '其他奖金管理',
		useRightPeople: '用对人',
		recruitmentManagement: '招聘管理'
	}
};
