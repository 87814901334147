import Layout from '@/layout';
import { Noop } from '@/utils/eleNoop';

// 分好钱
const distributionOfWelfare = {
	path: '/remuneration',
	component: Layout,
	name: 'Remuneration',
	redirect: '/remuneration/salaryRangeSetting',
	meta: {
		title: 'distributionOfWelfare',
		icon: '薪酬激励@1x'
	},
	children: [
		{
			path: 'salaryRangeSetting',
			name: 'SalaryRangeSetting',
			component: () => import('@/views/remuneration/salaryRangeSetting/list'),
			meta: {
				title: 'salaryRangeSetting'
			}
		},
		{
			path: 'wageParameter',
			name: 'WageParameter',
			redirect: '/remuneration/wageParameter/remunerationSetting',
			component: () => import('@/views/remuneration/wageParameter/index'),
			meta: {
				title: 'wageParameter'
			},
			children: [
				{
					path: 'remunerationSetting',
					name: 'RemunerationSetting',
					component: () => import('@/views/remuneration/remunerationSetting/settingIndex'),
					meta: {
						title: 'remunerationSetting'
					}
				},
				{
					path: 'current',
					name: 'RemunerationCurrent',
					component: () => import('@/views/remuneration/monthMoney/index'),
					meta: {
						title: 'whenMonthlyWages'
					}
				},
				{
					path: 'history',
					name: 'RemunerationHistory',
					component: () => import('@/views/remuneration/monthMoney/index'),
					meta: {
						title: 'salaryHistory'
					}
				},
				{
					path: 'analysis',
					name: 'RemunerationAnalysis',
					component: () => import('@/views/remuneration/remunerationAnalysis/analysis'),
					meta: {
						title: 'analysisOfTheCompensation'
					}
				}
			]
		},
		{
			path: 'allocationOfBonus',
			name: 'AllocationOfBonus',
			redirect: '/remuneration/allocationOfBonus/annual',
			component: () => import('@/views/remuneration/allocationOfBonus/index'),
			meta: {
				title: 'allocationOfBonus'
			},
			children: [
				{
					path: 'annual',
					name: 'RemunerationAnnual',
					component: () => import('@/views/remuneration/annual/index'),
					meta: {
						title: 'YearendBonusManagement'
					}
				},
				{
					path: 'otherBonus',
					name: 'OtherBonusManagement',
					component: () => import('@/views/remuneration/otherBonusManagement/index'),
					meta: {
						title: 'otherBonusManagement'
					}
				}
			]
		},
		{
			path: 'equityManagement',
			name: 'EquityManagement',
			// component: () => import('@/views/remuneration/equityManagement/index.vue'),
			component: Noop,
			redirect: '/remuneration/equityManagement/generalSituation',
			meta: {
				title: '股权管理'
			},
			children: [
				{
					path: 'generalSituation',
					name: 'GeneralSituation',
					component: () => import('@/views/remuneration/equityManagement/generalSituation'),
					meta: {
						title: '股权概况'
					}
				},
				{
					path: 'details',
					name: 'EquityDetails',
					component: () => import('@/views/remuneration/equityManagement/index'),
					meta: {
						title: '股权明细'
					}
				},
				{
					path: 'coefficientAdjustment',
					name: 'CoefficientAdjustment',
					component: () => import('@/views/remuneration/equityManagement/coefficientAdjustment'),
					hidden: true,
					meta: {
						title: '年度行权系数调整'
					}
				},
				{
					path: 'adjustTheRecord',
					name: 'AdjustTheRecord',
					component: () => import('@/views/remuneration/equityManagement/adjustTheRecord'),
					hidden: true,
					meta: {
						title: '调整记录'
					}
				}
			]
		}
	]
};

export default distributionOfWelfare;
