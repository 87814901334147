<template>
	<!-- v-if="$store.state.app.permission||isLogin" -->
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { getCookie } from '@/utils/util';

export default {
	name: 'App',
	data() {
		return {
			fisishInit: null,
			isLogin: false
		};
	},
	watch: {
		$route: {
			handler(val) {
				if (val.name === 'login') {
					this.isLogin = true;
				}
			}
		}
	},
	methods: {
		getDict() {
			this.$store.dispatch('app/getDict');
		},
		setPermission() {
			this.$store.dispatch('app/setPermission');
		},
		getplanrange() {
			this.$store.dispatch('app/setrange');
		}
	},
	async mounted() {
		const token = getCookie('token');

		if (token) {
			this.getDict();
			// this.setPermission();
		}

		// console.log(/^http(s)?:\/\/.+\/wap(\/)?.*$/.test(location.href))
		// if (!/^http(s)?:\/\/.+\/wap(\/)?.*$/.test(location.href)) {
		//   this.getplanrange();
		// }
	}
};
</script>
<style lang="scss">
@import url('./styles/reset.css');
</style>
